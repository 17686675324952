import React from 'react'
import { Field } from 'react-final-form'

type DecisionFieldInputType = {
  name: string
  render: (props: any) => JSX.Element
  label?: string
}

const DecisionField: React.FC<DecisionFieldInputType> = ({
  render,
  ...props
}) => {
  return (
    <Field
      {...props}
      parse={value => value}
      allowNull={true}
      render={({ input, meta, ...rest }) => {
        return render({
          ...input,
          ...meta,
          error:
            (meta.touched || meta.submitFailed) &&
            (meta.error || meta.submitError),
          ...rest
        })
      }}
    />
  )
}

export default DecisionField
