import gql from 'graphql-tag'

export const GET_USUARIO = gql`
  query {
    meuUsuarioPortal {
      email
      empresa
    }
  }
`
