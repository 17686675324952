import styled from '@emotion/styled'
import React from 'react'

type LeftRightStackProps = {
  leftComponentList: JSX.Element[]
  rightComponentList: JSX.Element[]
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  flex-wrap: wrap;
`

const Stack = styled('div')`
  display: flex;
  flex-direction: row;

  justify-content: end;
  /* align-items: center; */

  flex-wrap: wrap;
`

const StackRight = styled(Stack)`
  margin-left: auto;
  justify-content: flex-end;
`

const StackLeft = styled(Stack)`
  margin-right: auto;
  justify-content: flex-start;
`

const StackItem = styled('div')`
  display: flex;
  align-items: center;
  min-height: 6rem;
  min-width: 6rem;
`

const StackItemLeft = styled(StackItem)`
  margin-right: 1rem;
`

const StackItemRight = styled(StackItem)`
  margin-left: 1rem;
`

const HorizontalLeftRightStack: React.FC<LeftRightStackProps> = ({
  leftComponentList,
  rightComponentList
}) => {
  return (
    <Wrapper>
      <StackLeft>
        {leftComponentList.map((Component, index) => (
          <StackItemLeft key={index}>{Component}</StackItemLeft>
        ))}
      </StackLeft>
      <StackRight>
        {rightComponentList.map((Component, index) => (
          <StackItemRight key={index}>{Component}</StackItemRight>
        ))}
      </StackRight>
    </Wrapper>
  )
}

export default HorizontalLeftRightStack
