import { createMuiTheme, darken } from '@material-ui/core/styles'

export const colorPrimary = '#252e4d'
export const colorSecondary = '#FF7E40'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1C61AB',
      // dark: '#1A2542',
      light: '#5C8EDD',
      contrastText: '#fff'
    },
    secondary: {
      main: '#FF7E40',
      dark: '#E84C0E',
      contrastText: '#000'
    }
  },
  overrides: {
    MuiTooltip: { tooltip: { fontSize: '1.4rem' } },

    MuiInputBase: { input: { fontSize: '1.4rem' } },

    MuiInputLabel: {
      root: {
        fontSize: '1.4rem'
      }
    },

    MuiFormHelperText: {
      root: {
        fontSize: '1rem'
      }
    },

    MuiChip: {
      label: {
        fontSize: '1.2rem'
      }
    },

    MuiButtonBase: {
      root: {
        fontSize: '1.4rem !important'
      }
    },

    MuiButton: {
      root: {
        borderRadius: '2px',
        fontSize: '1.4rem'
      },

      containedPrimary: {
        backgroundColor: colorPrimary,
        color: 'white',
        '&:hover': { backgroundColor: `${darken(colorPrimary, 0.1)}` }
      },

      containedSecondary: {
        backgroundColor: 'white',
        color: '#292941',
        '&:hover': { backgroundColor: `${darken('#FFFFFF', 0.1)}` }
      }
    }
  },

  typography: {
    // suppressDeprecationWarnings: true,
    fontFamily: [
      'Poppins',
      'Open Sans',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'sans-serif'
    ].join(','),
    fontSize: 14,
    htmlFontSize: 14
  }
})

export default theme
