import React, { useState } from 'react'
import styled from '@emotion/styled'
import { get } from 'lodash'
import { IconButton } from '@material-ui/core'
import { EMITIR_FATURAS } from 'src/apollo/faturas/queries'
import { FilterNoneRounded } from '@material-ui/icons'
import { toLocaleCurrency } from 'src/components/utils'
import { formatMySQLDate, formatDateString } from 'src/utils/dates'

import TextFieldComponent from 'src/components/inputs/TextField'
import TooltipComponent from 'src/components/Tooltip/Tooltip'
import useDecisionSnackbar from 'src/hooks/useSnackbar'
import InfoDialog from 'src/components/Dialogs/InfoDialog'
import client from 'src/config/apolloClient'
import { ButtonStyled } from 'src/components/DataGrid/utils'

type FaturarButton = {
  row: any
}

export const FaturarButton: React.FC<FaturarButton> = ({ row }) => {
  const {
    openSuccessSnackbar,
    openInfoSnackbar,
    openErrorSnackbar
  } = useDecisionSnackbar()

  const numeroDocumento = get(row, 'numeroDocumento')
  const urlDocumentoBruto = get(row, 'faturamento.urlDocumentoBruto')
  const urlDocumentoLiquido = get(row, 'faturamento.urlDocumentoLiquido')

  const handleEmissaoFatura = async () => {
    try {
      openInfoSnackbar('Emitindo fatura')

      const emissaoResult = await client.query({
        query: EMITIR_FATURAS,
        variables: {
          fatura: parseInt(numeroDocumento),
          emissao: formatDateString(new Date())
        },
        fetchPolicy: 'no-cache'
      })

      const emissaoSuccess = get(
        emissaoResult,
        'data.meuUsuarioPortal.faturas[0].emitirFatura.success',
        false
      )
      const emissaoErrorMessage = get(
        emissaoResult,
        'data.meuUsuarioPortal.faturas[0].emitirFatura.errorMessage',
        null
      )
      const faturas = get(
        emissaoResult,
        'data.meuUsuarioPortal.faturas[0].emitirFatura.faturas',
        {}
      )

      if (emissaoSuccess) {
        const fileBuffer = get(faturas, 'data')

        if (fileBuffer) {
          const url = URL.createObjectURL(
            new Blob([Buffer.from(fileBuffer).buffer], {
              type: 'application/pdf'
            })
          )

          window.open(url)
          openSuccessSnackbar('Fatura emitida com sucesso!')
        }
      } else {
        openErrorSnackbar(emissaoErrorMessage)
      }
    } catch (err) {
      openErrorSnackbar()
    }
  }

  const handleDownloadFatura = () => {
    try {
      if (urlDocumentoBruto) {
        const win = window.open(urlDocumentoBruto, '_blank')
        win && win.focus && win.focus()
      }
      if (urlDocumentoLiquido) {
        const win = window.open(urlDocumentoLiquido, '_blank')
        win && win.focus && win.focus()
      }
    } catch (err) {
      openErrorSnackbar()
    }
  }

  return urlDocumentoBruto || urlDocumentoLiquido ? (
    <ButtonStyled
      color={'secondary'}
      variant={'contained'}
      onClick={
        urlDocumentoBruto || urlDocumentoLiquido
          ? handleDownloadFatura
          : handleEmissaoFatura
      }
    >
      Fatura
    </ButtonStyled>
  ) : (
    <div />
  )
}

const FieldsWrapper = styled('div')`
  display: grid;
  grid-template-rows: auto auto auto;

  grid-gap: 2rem;
`

type PagarButtonType = {
  fatura: any
}

export const PagarButton: React.FC<PagarButtonType> = ({ fatura }) => {
  const [showFaturaDialog, setShowFaturaDialog] = useState<boolean>(false)

  return (
    <>
      <InfoDialog
        title={
          'Para realizar o pagamento da fatura utilize a linha digitável abaixo:'
        }
        open={showFaturaDialog}
        content={<FaturaComponent fatura={fatura} />}
        onClose={() => setShowFaturaDialog(false)}
      />

      <ButtonStyled
        color={'secondary'}
        variant={'contained'}
        onClick={() => setShowFaturaDialog(true)}
      >
        Boleto
      </ButtonStyled>
    </>
  )
}

type BoletoButtonType = {
  url: string
}

export const BoletoButton: React.FC<BoletoButtonType> = ({ url }) => {
  return (
    <ButtonStyled
      color={'secondary'}
      variant={'contained'}
      onClick={() => {
        window.open(url)
      }}
    >
      Boleto
    </ButtonStyled>
  )
}

const FaturaComponent = ({ fatura }: any) => {
  const valor = get(fatura, 'valor')
  const vencimento = get(fatura, 'vencimento')
  const linhaDigitavel = get(fatura, 'linhaDigitavel')

  return (
    <FieldsWrapper>
      <TextFieldComponent
        disabled
        label={' '}
        value={linhaDigitavel}
        onChange={() => {}}
        endAdornments={[
          <TooltipComponent key={1} text="Copiar Linha Digitável">
            <IconButton
              size={'small'}
              onClick={() => navigator.clipboard.writeText(linhaDigitavel)}
            >
              <FilterNoneRounded />
            </IconButton>
          </TooltipComponent>
        ]}
      />

      <div>
        <div>{`Valor: ${toLocaleCurrency(valor)}`}</div>
        <div>{`Vencimento: ${formatMySQLDate(vencimento)}`}</div>
      </div>
    </FieldsWrapper>
  )
}
