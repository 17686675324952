import React, { useState } from 'react'

import styled from '@emotion/styled'
import { Chip, IconButton } from '@material-ui/core'

import Datepicker from 'src/components/inputs/Datepicker'

import HorizontalLeftRightStack from 'src/components/wappers/HorizontalLeftRightStack'
import {
  AttachFileRounded,
  CheckCircleRounded,
  CloudDownloadRounded,
  PanoramaFishEyeRounded
} from '@material-ui/icons'
import { QueryResult } from 'react-apollo'
import { get } from 'lodash'
import { toLocaleNumber } from 'src/components/utils'
import { formatMySQLDate, formatDateString } from 'src/utils/dates'
import DecisionIconButton from 'src/components/Buttons/IconButton'
import DataGridScroll from 'src/components/DataGridServerPagination/DataGridServerPagination'
import {
  reduceOnlyDefined,
  removeEmptyArrays,
  sequentialBatchLimitOffsetAsync
} from 'src/utils/graphql'
import { endOfMonth, startOfMonth } from 'date-fns'
import { FaturarButton, PagarButton, BoletoButton } from './FaturaComponent'
import { PageTitle, PagePaper } from 'src/pages/utils'
import { handleExport } from 'src/utils/xlsexport'
import { Form, FormSpy, Field } from 'react-final-form'
import { validate } from './validation'
import QueryDebounced from 'src/components/Apollo/QueryDebounced'
import FaturaAnexosModal from './FaturaAnexosModal'
import { NotaFiscalButton } from './FaturaNotaFiscalButton'
import useDecisionSnackbar from 'src/hooks/useSnackbar'
import { Pagination } from 'src/components/Tables/utils/utils'
import client from 'src/config/apolloClient'
import {
  GET_FATURAMENTO_COMPLEMENTO,
  GET_FATURAMENTO_COMPLEMENTO_COUNT
} from 'src/apollo/faturamentosComplementos/queries'
import TextField from 'src/components/inputs/TextField'
import { UPDATE_FATURA } from 'src/apollo/faturas/mutation'
import TooltipComponent from 'src/components/Tooltip/Tooltip'

const DateWrapper = styled('div')`
  width: 16rem;
`

const FiltersWrapper = styled('div')`
  margin-bottom: 3rem;
`

const CheckCircleRoundedStyled = styled(CheckCircleRounded)`
  fill: #54d891 !important;
`
const PanoramaFishEyeRoundedStyled = styled(PanoramaFishEyeRounded)`
  fill: grey !important;
`

type TableARow = {
  id: string
  origem: string
  numeroDocumento: number
  vencimento: string
  status: string
  valor: number
  moeda: string
  // fatura: number
  cliente: string
  emissao: string
  requisicoes: any[]
  faturamento: any
  dataBaixa: Date
  linhaDigitavel: string
  portalChecked: boolean
}

const parseFaturas = (records = []): TableARow[] =>
  records.map(
    (fatura: any): TableARow => {
      return {
        id: get(fatura, 'id'),
        origem: get(fatura, 'origem'),
        numeroDocumento: parseInt(get(fatura, 'documento')),
        vencimento: get(fatura, 'vencimento'),
        status: get(fatura, 'status'),
        valor: get(fatura, 'valor'),
        moeda: get(fatura, 'moeda.simbolo'),
        emissao:
          get(fatura, 'emissao') &&
          formatDateString(new Date(get(fatura, 'emissao'))),
        cliente: get(fatura, 'cliente.nome'),
        dataBaixa: get(fatura, 'pagamento'),
        linhaDigitavel: get(fatura, ''),
        faturamento: get(fatura, 'faturamento'),
        requisicoes: get(fatura, 'faturamento.requisicoes') || [],
        portalChecked: get(fatura, 'faturamento.portalChecked')
      }
    }
  )

const parseOnExport = (records: TableARow[]) => {
  return Array.from(records, fatura => ({
    id: get(fatura, 'id') || '',
    origem: get(fatura, 'origem') === 'F' ? 'Fatura' : 'Complemento',
    numeroDocumento: parseInt(get(fatura, 'documento')) || '',
    vencimento:
      (get(fatura, 'vencimento') &&
        formatMySQLDate(get(fatura, 'vencimento'))) ||
      '',
    status: get(fatura, 'faturamento.status') || '',
    valor: toLocaleNumber(get(fatura, 'valor') || 0) || '',
    moeda: get(fatura, 'moeda.simbolo') || '',
    emissao:
      (get(fatura, 'emissao') && formatMySQLDate(get(fatura, 'emissao'))) || '',
    cliente: get(fatura, 'cliente.nome') || '',
    dataBaixa:
      (get(fatura, 'pagamento') && formatMySQLDate(get(fatura, 'pagamento'))) ||
      '',
    faturamento: get(fatura, 'faturamento.id') || '',
    requisicoes:
      (get(fatura, 'faturamento.requisicoes') &&
        get(fatura, 'faturamento.requisicoes')
          .map((faturamento: any) => faturamento?.id)
          .filter((el: any) => el)
          .join(', ')) ||
      '',
    portalChecked: get(fatura, 'faturamento.portalChecked') || ''
  }))
}
const HomePage: React.FC = () => {
  const [anexosFaturaId, setAnexosFaturaId] = useState<number>(0)

  const [start] = useState(startOfMonth(new Date()))
  const [end] = useState(endOfMonth(new Date()))
  const {
    openErrorSnackbar,
    openInfoSnackbar,
    openSuccessSnackbar
  } = useDecisionSnackbar()
  const [isExporting, setIsExporting] = useState<boolean>(false)

  const OrigemComponent = ({ value }: any) => {
    if (value === 'C') {
      return 'Complemento'
    } else {
      return 'Fatura'
    }
  }

  const updateChecked = async (id: any, data: any) => {
    try {
      const response = await client.mutate({
        mutation: UPDATE_FATURA,
        variables: {
          id: id,
          data: {
            portalChecked: !data
          }
        },
        refetchQueries: ['faturamentosUnionEntradasSaidasComplementares']
      })

      const success = get(
        response,
        'data.portalUsuarioFaturamento.update.success'
      )

      const errorMessage = get(
        response,
        'data.portalUsuarioFaturamento.update.error'
      )

      if (success) {
        openSuccessSnackbar()
      } else {
        openErrorSnackbar(errorMessage)
      }
    } catch (err) {
      openErrorSnackbar()
    }
  }

  const StatusComponent = ({ row }: any) => {
    const origem = get(row, 'origem')
    const dataBaixa = get(row, 'dataBaixa')
    const valor = get(row, 'valor')

    const statusFaturamento = get(row, 'faturamento.status') || ''
    if (origem === 'C') {
      if (valor >= 0 && dataBaixa) {
        // Complemento pago
        return (
          <Chip
            size={'small'}
            style={{
              backgroundColor: '#8CC676',
              color: 'white'
            }}
            label={'PAGO'}
          />
        )
      } else if (valor < 0 && dataBaixa) {
        // valor negativo baixado é um estorno
        return (
          <Chip
            size={'small'}
            style={{
              backgroundColor: '#f44336',
              color: 'white'
            }}
            label={'ESTORNADO'}
          />
        )
      } else {
        return (
          <Chip
            size={'small'}
            style={{
              backgroundColor: '#FF653F',
              color: 'white'
            }}
            label={'PENDENTE'}
          />
        )
      }
    } else {
      // caso origem faturamento (fatura e parcelas)
      if (statusFaturamento === 'PENDENTE') {
        return (
          <Chip
            style={{ backgroundColor: '#FF653F', color: 'white' }}
            size={'small'}
            label={'PENDENTE'}
          />
        )
      } else if (statusFaturamento === 'CANCELADA') {
        return (
          <Chip
            style={{ backgroundColor: '#9e9492', color: 'white' }}
            size={'small'}
            label={'CANCELADA'}
          />
        )
      } else if (statusFaturamento === 'PAGA') {
        return (
          <Chip
            style={{ backgroundColor: '#8CC676', color: 'white' }}
            size={'small'}
            label={'PAGA'}
          />
        )
      } else if (statusFaturamento === 'PAGA_PARCIAL') {
        return (
          <Chip
            style={{ backgroundColor: '#E4D354', color: 'white' }}
            size={'small'}
            label={'PAGA PARCIAL'}
          />
        )
      }
    }
  }

  const columns = [
    {
      field: 'portalChecked',
      headerName: ' ',
      flex: 5,
      renderCell: ({ value, row }: any) => {
        const id = get(row, 'faturamento.id')
        const checked = get(row, 'faturamento.portalChecked')

        if (id) {
          return (
            <TooltipComponent text="Conferência">
              <IconButton
                onClick={() => {
                  updateChecked(id, checked)
                }}
              >
                {checked ? (
                  <CheckCircleRoundedStyled />
                ) : (
                  <PanoramaFishEyeRoundedStyled />
                )}
              </IconButton>
            </TooltipComponent>
          )
        }
      },
      sortable: false
    },
    {
      field: 'vencimento',
      headerName: 'Vencimento',
      flex: 12,
      valueFormatter: ({ value }: any) => formatMySQLDate(value),
      sortable: false
    },
    {
      field: 'origem',
      headerName: 'Origem',
      flex: 12,
      renderCell: OrigemComponent,
      sortable: false
    },
    {
      field: 'dataBaixa',
      headerName: 'Status',
      flex: 15,
      renderCell: StatusComponent,
      sortable: false
    },
    {
      field: 'valor',
      headerName: 'Valor',
      flex: 15,
      valueFormatter: ({ value, row }: any) => {
        const moeda = get(row, 'moeda') || ''
        return `${moeda} ${toLocaleNumber(value || 0)}`
      },
      sortable: false
    },
    {
      field: 'numeroDocumento',
      headerName: 'Fatura',
      flex: 10,
      sortable: false
    },
    {
      field: 'emissao',
      headerName: 'Emissão',
      flex: 12,
      valueFormatter: ({ value }: any) => formatMySQLDate(value),
      sortable: false
    },
    {
      field: 'cliente',
      headerName: 'Cliente',
      flex: 30,
      sortable: false
    },
    {
      field: 'faturamento',
      headerName: ' ',
      flex: 10,
      renderCell: NotaFiscalButton,
      sortable: false
    },
    {
      field: 'x',
      headerName: ' ',
      flex: 10,
      renderCell: FaturarButton,
      sortable: false
    },
    {
      field: 'linhaDigitavel',
      headerName: ' ',
      flex: 10,
      renderCell: ({ value, row }: any) => {
        const boletoStatus = get(row, 'faturamento.boleto.status.codigo')
        const boletoUrl = get(row, 'faturamento.boleto.url')
        const faturaStatus = get(row, 'faturamento.status')

        const baixa = get(row, 'dataBaixa')
        if (!baixa) {
          if (
            boletoStatus === 'REGISTRADO' &&
            faturaStatus === 'PENDENTE' &&
            boletoUrl
          ) {
            return <BoletoButton url={boletoUrl} />
          } else if (value) {
            return <PagarButton fatura={row} />
          } else {
            return <div />
          }
        } else {
          return <div />
        }
      },
      sortable: false
    },
    {
      field: 'requisicoes',
      headerName: ' ',
      flex: 6,
      renderCell: ({ value = [], row }: any) => {
        const hasAnexo = (value || []).reduce((acc: any, curr: boolean) => {
          const anexos = get(curr, 'anexos') || []
          const hasAnexo = anexos.length > 0
          return acc || hasAnexo
        }, false)

        if (hasAnexo) {
          return (
            <IconButton
              onClick={() => {
                const id = get(row, 'numeroDocumento')
                if (id) {
                  setAnexosFaturaId(id)
                }
              }}
            >
              <AttachFileRounded />
            </IconButton>
          )
        } else {
          return <div />
        }
      },
      sortable: false
    }
  ]

  return (
    <>
      <FaturaAnexosModal
        faturaId={anexosFaturaId}
        onClose={() => setAnexosFaturaId(0)}
      />

      <Form
        initialValues={{
          startDate: start,
          endDate: end
          // numeroFatura
        }}
        validate={validate}
        onSubmit={() => {}}
        render={() => (
          <FormSpy subscription={{ values: true, valid: true }}>
            {({ valid, values: { numeroFatura, startDate, endDate } }) => {
              const filterFaturamento = removeEmptyArrays(
                reduceOnlyDefined({
                  numeroDocumento: numeroFatura,
                  vencimento_lte: endDate
                    ? formatDateString(endDate)
                    : undefined,
                  vencimento_gte: startDate
                    ? formatDateString(startDate)
                    : undefined
                })
              )

              const filterComplemento = removeEmptyArrays(
                reduceOnlyDefined({
                  codFatura: numeroFatura,
                  vencimento_lte: endDate
                    ? formatDateString(endDate)
                    : undefined,
                  vencimento_gte: startDate
                    ? formatDateString(startDate)
                    : undefined
                })
              )

              return (
                <Pagination
                  initialItemsPerPage={10}
                  resetProps={[startDate, endDate]}
                >
                  {({
                    pageIndex,
                    setPageIndex,
                    itemsPerPage,
                    setItemsPerPage
                  }) => (
                    <QueryDebounced
                      skip={!valid}
                      query={GET_FATURAMENTO_COMPLEMENTO}
                      variables={reduceOnlyDefined({
                        faturamentoFilter: filterFaturamento,
                        entradaSaidaComplementarFilter: filterComplemento,
                        limit: itemsPerPage,
                        offset: pageIndex
                      })}
                      fetchPolicy={'no-cache'}
                    >
                      {(result: QueryResult) => {
                        const loading = result.loading

                        const count =
                          get(
                            result,
                            'data.meuUsuarioPortal.faturamentosUnionEntradasSaidasComplementaresCount'
                          ) || 0

                        const records =
                          get(
                            result,
                            'data.meuUsuarioPortal.faturamentosUnionEntradasSaidasComplementares'
                          ) || []

                        const rows: TableARow[] = parseFaturas(records)

                        const onExport = async () => {
                          try {
                            setIsExporting(true)

                            const response = await client.query({
                              query: GET_FATURAMENTO_COMPLEMENTO_COUNT,
                              variables: {
                                faturamentoFilter: filterFaturamento,
                                entradaSaidaComplementarFilter: filterComplemento
                              },
                              fetchPolicy: 'no-cache'
                            })

                            const count =
                              get(
                                response,
                                'data.meuUsuarioPortal.faturamentosUnionEntradasSaidasComplementaresCount'
                              ) || 0

                            const faturas = await sequentialBatchLimitOffsetAsync(
                              3,
                              count,
                              async (limit: number, offset: number) => {
                                const response = await client.query({
                                  query: GET_FATURAMENTO_COMPLEMENTO,
                                  variables: {
                                    faturamentoFilter: filterFaturamento,
                                    entradaSaidaComplementarFilter: filterComplemento,
                                    limit,
                                    offset
                                  },
                                  fetchPolicy: 'no-cache'
                                })

                                return get(
                                  response,
                                  'data.meuUsuarioPortal.faturamentosUnionEntradasSaidasComplementares'
                                )
                              }
                            )

                            if (faturas.length === 0) {
                              openInfoSnackbar(
                                'Não foram encontrados registros'
                              )
                              return
                            }
                            handleExport(
                              parseOnExport(faturas),
                              'xls',
                              'faturas'
                            )
                            openSuccessSnackbar(
                              `${faturas.length} registros exportados`
                            )
                          } catch (err) {
                            openErrorSnackbar('Erro ao exportar registros')
                          } finally {
                            setIsExporting(false)
                          }
                        }

                        return (
                          <>
                            <PageTitle>FATURAS</PageTitle>
                            <PagePaper>
                              <FiltersWrapper>
                                <HorizontalLeftRightStack
                                  leftComponentList={[
                                    // <div key={0}>{'Período Vencimento'}</div>,
                                    <DateWrapper key={1}>
                                      <Field
                                        name={'startDate'}
                                        render={({ input, meta, ...rest }) => {
                                          return (
                                            <Datepicker
                                              label={'Vencimento De'}
                                              placeholder={'Início'}
                                              {...input}
                                              {...meta}
                                              {...rest}
                                            />
                                          )
                                        }}
                                      />
                                    </DateWrapper>,
                                    <DateWrapper key={2}>
                                      <Field
                                        name={'endDate'}
                                        render={({ input, meta, ...rest }) => (
                                          <Datepicker
                                            label={'Vencimento Até'}
                                            placeholder={'Fim'}
                                            {...input}
                                            {...meta}
                                            {...rest}
                                          />
                                        )}
                                      />
                                    </DateWrapper>,
                                    <DateWrapper key={1}>
                                      <Field
                                        name={'numeroFatura'}
                                        render={({ input, meta, ...rest }) => (
                                          <TextField
                                            label={'Fatura'}
                                            {...input}
                                            {...meta}
                                            {...rest}
                                          />
                                        )}
                                      />
                                    </DateWrapper>
                                  ]}
                                  rightComponentList={[
                                    <DecisionIconButton
                                      onClick={onExport}
                                      key={1}
                                    >
                                      <CloudDownloadRounded />
                                    </DecisionIconButton>
                                  ]}
                                />
                              </FiltersWrapper>

                              <DataGridScroll
                                rows={rows}
                                loading={loading || isExporting}
                                columns={columns}
                                setPageSize={setItemsPerPage}
                                setPage={page => setPageIndex(page - 1)}
                                rowCount={count}
                                pageIndex={1}
                                pageSize={itemsPerPage}
                              />
                            </PagePaper>
                          </>
                        )
                      }}
                    </QueryDebounced>
                  )}
                </Pagination>
              )
            }}
          </FormSpy>
        )}
      />
    </>
  )
}

export default HomePage
