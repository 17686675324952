import React from 'react'
import Dropzone from 'react-dropzone'
import { CircularProgress, Button } from '@material-ui/core'

import styled from '@emotion/styled'
import { CloudUpload } from '@material-ui/icons'

import WarningIcon from '@material-ui/icons/Warning'

// /* background-color: lightgrey; */
const Wrapper = styled('div')`
  min-height: 23rem;
  border-style: dashed;
  border-width: 2px;
  border-color: lightgrey;

  margin-bottom: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  outline: 0px;
`
// /* ${defaultFont}; */
const CloudUploadStyled = styled(CloudUpload)`
  min-height: 6rem;
  min-width: 6rem;
  fill: lightgray;
`

const WarningIconStyled = styled(WarningIcon)`
  min-height: 6rem;
  min-width: 6rem;
`
// /* fill: ${colorWarning}; */

const ButtonStyled = styled(Button)`
  font: inherit;
  text-transform: none;
`

type DropzoneTypes = {
  onSave?: (props: any) => void
  loading: boolean
  fallbackWarning: string
}

const DropzoneComponent: React.FC<DropzoneTypes> = ({
  onSave,
  loading,
  fallbackWarning,
  ...props
}) => {
  return (
    <Dropzone onDrop={onSave} {...props}>
      {({ getRootProps, getInputProps }) => (
        <Wrapper {...getRootProps()}>
          {loading ? (
            <CircularProgress size={30} />
          ) : onSave ? (
            <>
              <input {...getInputProps()} />
              <CloudUploadStyled />
              <div>{'Arraste e solte arquivo'}</div>
              <div>{'ou'}</div>
              <ButtonStyled variant={'contained'} color={'primary'}>
                {'Escolher arquivo'}
              </ButtonStyled>
            </>
          ) : (
            <>
              <WarningIconStyled />
              <div>{fallbackWarning}</div>
            </>
          )}
        </Wrapper>
      )}
    </Dropzone>
  )
}

export default DropzoneComponent
