import gql from 'graphql-tag'

export const GET_FATURA = gql`
  query faturas($id: Int!) {
    meuUsuarioPortal {
      faturas(data: { faturasIds: [$id], allFields: true }) {
        nroFatura
        valorLiquido
        cliente
        dataVencimentoDS
        # dataEmissaoDS
        dataFaturaDS
        dataBaixa
        linhaDigitavel

        requisicoes {
          id
          anexos {
            id
            nome
            descricao
            url
            ultimaAlteracaoData
          }
        }
      }
    }
  }
`

export const GET_FATURAS_IDS = gql`
  query faturas(
    $start: DateString
    $end: DateString
    $orderBy: [OrderByInput!]
  ) {
    meuUsuarioPortal {
      faturamentosPortal(
        _filter: {
          vencimento_lte: $end
          vencimento_gte: $start
          # origem_in: [P]
          _moedaFilter: { simbolo: "BRL" }
          _orderby: $orderBy
        }
      ) {
        id
      }
    }
  }
`

export const GET_FATURAS = gql`
  query faturas($filter: FaturamentoPortalFilter) {
    meuUsuarioPortal {
      faturamentosPortal(_filter: $filter) {
        id
        vencimento
        emissao
        origem
        status
        valor
        numeroDocumento
        cliente {
          id
          nome
        }
        pagamento
        notaFiscalTerceiro
        notaFiscal {
          name
          url
        }
        boleto {
          url
          status {
            codigo
          }
        }
        urlDocumentoBruto
        urlDocumentoLiquido
        requisicoes {
          id
          anexos {
            nome
          }
        }
      }
    }
  }
`

export const EMITIR_FATURAS = gql`
  query emitirFatura($fatura: Int!, $emissao: DateString!) {
    meuUsuarioPortal {
      email
      faturas(data: { faturasIds: [$fatura], allFields: false }) {
        nroFatura
        emitirFatura(emissao: $emissao) {
          success
          errorMessage
          faturas
        }
      }
    }
  }
`
