import gql from 'graphql-tag'

export const GET_RELATORIOS = gql`
  query {
    meuUsuarioPortal {
      templates {
        id
        nome
        descricao
      }
    }
  }
`

export const GET_RELATORIO = gql`
  query template($id: Int!, $filter: SalesFilterInput) {
    meuUsuarioPortal {
      template(id: $id) {
        id
        nome
        descricao
        fields
        filters
        sales(_filters: $filter) {
          id
          unidadeNegocio
          localizador
        }
      }
    }
  }
`

export const GET_RELATORIO_EXPOTACAO = gql`
  query template($id: Int!, $filter: SalesFilterInput) {
    meuUsuarioPortal {
      template(id: $id) {
        id
        sales(_filters: $filter) {
          id
          unidadeNegocio
          localizador
        }
      }
    }
  }
`

export const dynamicExportSales = (fieldsToFetch: string[]) => {
  return gql`
    query ExportarVendas($id: Int!, $filters: SalesFilterInput) {
      meuUsuarioPortal {
      	template(id: $id) {
        	id
        	sales( _filters: $filters) {
        		${fieldsToFetch.join('\n')}
					}
				}
			}
		}
		`
}
