import React from 'react'
import styled from '@emotion/styled'
import DecisionIconButton from 'src/components/Buttons/IconButton'
import { ExitToAppRounded } from '@material-ui/icons'
import { Query, QueryResult } from 'react-apollo'
import { GET_USUARIO } from 'src/apollo/usuario/queires'
import { get } from 'lodash'
import useDecisionSnackbar from 'src/hooks/useSnackbar'
import client from 'src/config/apolloClient'
import { useHistory } from 'react-router-dom'
import { LOGOUT } from 'src/apollo/usuario/mutations'
import DecisionHeaderButton from 'src/components/Buttons/HeaderButton'

const Separator = styled('div')`
  margin-left: auto;
`

const Header = styled('div')`
  position: sticky;

  background-color: #292941;

  z-index: 100;

  height: 5rem;

  display: flex;
  flex-direction: row;

  top: 0;
  right: 0;
  left: 0;

  padding-right: 1rem;
  padding-left: 1rem;

  align-items: center;
  font-size: 1.2rem;
  color: white;
`

const Capitalize = styled('div')`
  text-transform: capitalize;
`

const Logo = styled('img')`
  width: 5rem;
  padding: 1rem;
`

const StickyHeader = (): JSX.Element => {
  const { openErrorSnackbar } = useDecisionSnackbar()
  const empresa = localStorage.getItem('empresa') || 'Nome Empresa'
  const history = useHistory()

  const pathname = get(history, 'location.pathname')

  const handleLogout = async () => {
    try {
      const response = await client.mutate({ mutation: LOGOUT })

      const success = get(response, 'data.logoutPortalUsuario')

      if (success) {
        history.push('/login')
      }
    } catch (err) {
      openErrorSnackbar()
    }
  }

  return (
    <Header>
      <Logo src={'/images/decision_systems_logo.png'} />
      <Capitalize>{`Área do Cliente | ${empresa}`}</Capitalize>
      <Separator />
      <DecisionHeaderButton
        // @ts-ignore
        selected={pathname === '/faturas'}
        onClick={() => history.push('/faturas')}
      >
        Financeiro
      </DecisionHeaderButton>

      <DecisionHeaderButton
        // @ts-ignore
        selected={pathname === '/relatorios'}
        onClick={() => history.push('/relatorios')}
      >
        Relatórios
      </DecisionHeaderButton>
      <DecisionHeaderButton
        // @ts-ignore
        selected={pathname === '/configuracoes'}
        onClick={() => history.push('/configuracoes')}
      >
        Configurações
      </DecisionHeaderButton>
      <Separator />
      <Query query={GET_USUARIO}>
        {(result: QueryResult) => {
          const email = get(result, 'data.meuUsuarioPortal.email')
          return <div>{email}</div>
        }}
      </Query>
      <DecisionIconButton onClick={handleLogout}>
        <ExitToAppRounded style={{ color: 'white' }} />
      </DecisionIconButton>
    </Header>
  )
}

export default StickyHeader
