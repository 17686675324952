import gql from 'graphql-tag'

export const GET_FATURAMENTO_COMPLEMENTO_COUNT = gql`
  query faturamentosUnionEntradasSaidasComplementares(
    $faturamentoFilter: FaturamentoPortalFilter
    $entradaSaidaComplementarFilter: EntradaSaidaComplementarPortalFilter
    $origem_in: [FatuturaComplementoOrigemPortalEnum!]
  ) {
    meuUsuarioPortal {
      faturamentosUnionEntradasSaidasComplementaresCount(
        _faturamentoFilter: $faturamentoFilter
        _entradaSaidaComplementarFilter: $entradaSaidaComplementarFilter
        origem_in: $origem_in
      )
    }
  }
`

export const GET_FATURAMENTO_COMPLEMENTO = gql`
  query faturamentosUnionEntradasSaidasComplementares(
    $faturamentoFilter: FaturamentoPortalFilter
    $entradaSaidaComplementarFilter: EntradaSaidaComplementarPortalFilter
    $limit: Int
    $offset: Int
    $origem_in: [FatuturaComplementoOrigemPortalEnum!]
  ) {
    meuUsuarioPortal {
      faturamentosUnionEntradasSaidasComplementaresCount(
        _faturamentoFilter: $faturamentoFilter
        _entradaSaidaComplementarFilter: $entradaSaidaComplementarFilter
        origem_in: $origem_in
      )
      faturamentosUnionEntradasSaidasComplementares(
        _faturamentoFilter: $faturamentoFilter
        _entradaSaidaComplementarFilter: $entradaSaidaComplementarFilter
        _limit: $limit
        _offset: $offset
        origem_in: $origem_in
      ) {
        id
        documento
        vencimento
        emissao
        pagamento
        origem
        valor
        moeda {
          id
          simbolo
        }
        cliente {
          id
          nome
        }
        faturamento {
          id
          status
          portalChecked
          pagamento
          notaFiscalTerceiro
          notaFiscal {
            name
            url
          }
          boleto {
            url
            status {
              codigo
            }
          }
          urlDocumentoBruto
          urlDocumentoLiquido
          requisicoes {
            id
            anexos {
              nome
            }
          }
        }
      }
    }
  }
`
