import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router'
import App from './App'
import reportWebVitals from './reportWebVitals'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ApolloProvider } from 'react-apollo'
import client from 'src/config/apolloClient'

import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@material-ui/core'
import theme from 'src/theme/theme'

import { history } from 'src/routes/history'

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <SnackbarProvider
              maxSnack={4}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <App />
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
