import React from 'react'
import Divider, { proportionallyTo } from 'src/components/Divider/Divider'
import DecisionField from 'src/components/Forms/Field'

import Datepicker from 'src/components/inputs/Datepicker'

type DecisionFieldDeAteTypes = {
  name: string
  label: string
}

export const DecisionFieldDeAte: React.FC<DecisionFieldDeAteTypes> = ({
  name,
  label
}) => {
  return (
    <Divider widthPercentage={proportionallyTo([1, 1, 1])} breakPointPx={800}>
      <DecisionField
        name={`${name}_gte`}
        label={`${label} De`}
        render={props => <Datepicker {...props} />}
      />
      <DecisionField
        name={`${name}_lte`}
        label={`${label} Até`}
        render={props => <Datepicker {...props} />}
      />
    </Divider>
  )
}
