import React from 'react'
import { Query } from 'react-apollo'
import useDebounce from 'src/components/hooks/useDebounce'

const QueryDebounced = (props: any) => {
  const debouncedProps = useDebounce(props, 500)

  return <Query {...debouncedProps} />
}

export default QueryDebounced
