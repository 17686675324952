import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation loginPortal($empresa: String!, $email: String!, $senha: String!) {
    loginPortal(empresa: $empresa, email: $email, senha: $senha) {
      success
      token
      error
    }
  }
`

export const LOGOUT = gql`
  mutation {
    logoutPortalUsuario
  }
`

export const FORGOT_MY_PASSWORD = gql`
  mutation forgotPasswordPortal($empresa: String!, $email: String!) {
    forgotPasswordPortal(empresa: $empresa, email: $email) {
      success
      successMessage
      errorMessage
    }
  }
`

export const UPDATE_PASSWORD_WITH_OLD = gql`
  mutation portalUserUpdatePasswordWithOld(
    $oldPassword: String!
    $newPassword: String!
  ) {
    portalUserUpdatePasswordWithOld(
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      success
      successMessage
      errorMessage
    }
  }
`

export const UPDATE_PASSWORD = gql`
  mutation portalUserUpdatePasswordResolver($newPassword: String!) {
    portalUserUpdatePassword(newPassword: $newPassword) {
      success
      successMessage
      errorMessage
    }
  }
`

export const ADD_NOTA_FISCAL = gql`
  mutation portalUsuarioFaturamento($id: Int!, $file: Upload!) {
    portalUsuarioFaturamento(id: $id) {
      addNotaFiscal(file: $file) {
        success
        error
      }
    }
  }
`

export const REMOVE_NOTA_FISCAL = gql`
  mutation portalUsuarioFaturamento($id: Int!) {
    portalUsuarioFaturamento(id: $id) {
      removeNotaFiscal {
        success
        error
      }
    }
  }
`

export const UPDATE_NOTA_FISCAL = gql`
  mutation portalUsuarioFaturamento(
    $id: Int!
    $data: PortalFaturamentoMutationInput!
  ) {
    portalUsuarioFaturamento(id: $id) {
      update(data: $data) {
        success
        error
        fieldErrors {
          notaFiscalTerceiro
        }
      }
    }
  }
`
