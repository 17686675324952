import styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'

const DecisionIconButton = styled(IconButton)`
  & svg {
    height: 2.6rem;
    width: 2.6rem;
  }
`

export default DecisionIconButton
