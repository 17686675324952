import React from 'react'
import { PageTitle, PagePaper } from 'src/pages/utils'

import { QueryResult } from 'react-apollo'
import DataGridScroll from 'src/components/DataGrid/DataGridScroll'

import QueryDebounced from 'src/components/Apollo/QueryDebounced'
import { GET_RELATORIOS } from 'src/apollo/templates/queries'
import { get } from 'lodash'
import { ButtonStyled } from 'src/components/DataGrid/utils'
import { useHistory } from 'react-router-dom'

const RelatoriosTable: React.FC = () => {
  const history = useHistory()

  const columns = [
    {
      field: 'nome',
      headerName: 'Relatório',
      flex: 15
    },

    {
      field: 'id',
      headerName: ' ',
      flex: 5,
      renderCell: ({ value }: any) => {
        return (
          <ButtonStyled
            color={'secondary'}
            variant={'contained'}
            onClick={() => {
              history.push(`/relatorios/${value}`)
            }}
          >
            Gerar Relatório
          </ButtonStyled>
        )
      }
    }
  ]

  return (
    <QueryDebounced query={GET_RELATORIOS}>
      {(result: QueryResult) => {
        const loading = result.loading
        const rows: any[] = get(result, 'data.meuUsuarioPortal.templates') || []

        return (
          <>
            <PageTitle>RELATÓRIOS</PageTitle>
            <PagePaper>
              <DataGridScroll rows={rows} loading={loading} columns={columns} />
            </PagePaper>
          </>
        )
      }}
    </QueryDebounced>
  )
}

export default RelatoriosTable
