import React from 'react'
import { PageTitle, PagePaper, PageFooter } from 'src/pages/utils'
import QueryDebounced from 'src/components/Apollo/QueryDebounced'
import { QueryResult } from 'react-apollo'
import { GET_RELATORIO, dynamicExportSales } from 'src/apollo/templates/queries'
import { get } from 'lodash'
import { Form } from 'react-final-form'
import { DecisionFieldDeAte } from './utils'
import { Button } from '@material-ui/core'
import useDecisionSnackbar from 'src/hooks/useSnackbar'
import client from 'src/config/apolloClient'
import {
  reduceOnlyDefined,
  removeEmptyArrays,
  reduceOnlyNotNull
} from 'src/utils/graphql'
import { formatDateStringOnObject } from 'src/utils/dates'
import { handleExport } from 'src/utils/xlsexport'
import { validate } from './validation'

const RelatorioExportacao: React.FC = props => {
  const {
    openSuccessSnackbar,
    openInfoSnackbar,
    openErrorSnackbar
  } = useDecisionSnackbar()
  const id = get(props, 'match.params.id')

  return (
    <QueryDebounced
      skip={!id}
      query={GET_RELATORIO}
      variables={{ id: parseInt(id) }}
    >
      {(result: QueryResult) => {
        const template = get(result, 'data.meuUsuarioPortal.template')
        const nome = get(template, 'nome')
        // const descricao = get(template, 'descricao') || ''

        const fields = get(template, 'fields') || []
        const filters = get(template, 'filters') || []

        const onSubmit = async (values: any) => {
          values = { ...values.baixa, ...values.emissao, ...values.vencimento }
          if (
            Object.keys(removeEmptyArrays(reduceOnlyDefined(values))).length ===
            0
          ) {
            openInfoSnackbar('Pelo menos um filtro deve ser selecionado!')
            return
          }

          values = formatDateStringOnObject(values, [
            'dataBaixaFatura_gte',
            'dataBaixaFatura_lte',
            'dataEmissaoFatura_gte',
            'dataEmissaoFatura_lte',
            'dataVencimentoFatura_gte',
            'dataVencimentoFatura_lte'
          ])

          try {
            openInfoSnackbar('Buscando registros...')

            const response = await client.query({
              query: dynamicExportSales(fields),
              variables: {
                id: parseInt(id),
                filters: removeEmptyArrays(
                  reduceOnlyNotNull(reduceOnlyDefined(values))
                )
              },
              fetchPolicy: 'no-cache'
            })

            const records =
              get(response, 'data.meuUsuarioPortal.template.sales') || []

            if (records.length > 0) {
              handleExport(records, 'xls', 'registros')
              openSuccessSnackbar(`${records.length} registros exportados`)
            } else {
              openInfoSnackbar('Nenhum registro encontrado')
            }
          } catch (e) {
            openErrorSnackbar('Algo deu errado')
          }
        }

        return (
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{
              baixa: {
                dataBaixaFatura_gte: null,
                dataBaixaFatura_lte: null
              },
              emissao: {
                dataEmissaoFatura_gte: null,
                dataEmissaoFatura_lte: null
              },
              vencimento: {
                dataVencimentoFatura_gte: null,
                dataVencimentoFatura_lte: null
              }
            }}
            render={({ handleSubmit }) => {
              return (
                <>
                  <PageTitle>RELATÓRIO</PageTitle>
                  <PagePaper>
                    {`Filtros do Relatório: ${nome}`}

                    {filters.includes('dataEmissaoFatura') && (
                      <DecisionFieldDeAte
                        name={'emissao.dataEmissaoFatura'}
                        label={'Fatura Emissão'}
                      />
                    )}
                    {filters.includes('dataBaixaFatura') && (
                      <DecisionFieldDeAte
                        name={'baixa.dataBaixaFatura'}
                        label={'Fatura Quitação'}
                      />
                    )}
                    {filters.includes('dataVencimentoFatura') && (
                      <DecisionFieldDeAte
                        name={'vencimento.dataVencimentoFatura'}
                        label={'Fatura Vencimento'}
                      />
                    )}

                    <PageFooter>
                      <Button
                        color={'primary'}
                        variant={'contained'}
                        onClick={handleSubmit}
                      >
                        SALVAR
                      </Button>
                    </PageFooter>
                  </PagePaper>
                </>
              )
            }}
          />
        )
      }}
    </QueryDebounced>
  )
}

export default RelatorioExportacao
