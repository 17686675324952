import Yup, { validateYupSchema } from 'src/utils/yup'
import { addMonths, isDate, isValid } from 'date-fns'

const schema = Yup.object().shape({
  numeroFatura: Yup.string().when('startDate', function (value: any) {
    const hasDate = isDate(value) && isValid(value)
		if (!hasDate) {
      // @ts-ignore
      return this.nullable(true).required(
        'Pelo menos um filtro deve ser preenchido'
      )
    }
  }),
  startDate: Yup.date().nullable(true),
  // .required()
  endDate: Yup.date()
    .nullable(true)
    // .required()
    .when('startDate', function (value: Date) {
      if (isDate(value) && isValid(value)) {
        // @ts-ignore
        return this.max(addMonths(new Date(value), 6))
          .min(new Date(value))
          .required()
      }
    })
})

export const validate = async (values: any): Promise<any> => {
  try {
    await validateYupSchema(schema, values)
  } catch (err: any) {
    return err
  }
}

const schemaNotaFiscalAnexo = Yup.object().shape({
  notaFiscalTerceiro: Yup.string().required().nullable(false),
  anexo: Yup.mixed().required('O arquivo da nota fiscal é Obrigatório')
})

export const validateNotaFiscalAnexo = async (values: any) => {
  try {
    await validateYupSchema(schemaNotaFiscalAnexo, values)
    return {}
  } catch (err: any) {
    return err
  }
}
