import React, { useState, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { makeStyles } from '@material-ui/core'
import { get } from 'lodash'
import { Pagination } from './CustomComponents'

const useStyles = makeStyles({
  root: {
    border: 0
  }
})

type DataGridScrollType = {
  rows: any[]
  loading: boolean
  columns: any[]

  minHeight?: string
  hideFooter?: boolean
}

const DataGridScroll: React.FC<DataGridScrollType> = ({
  rows = [],
  loading,
  columns,
  minHeight = '30rem',
  hideFooter = false
}) => {
  const classes = useStyles()

  const [minWidthRem, setMinWidthRem] = useState(0)

  useEffect(() => {
    const columnsX = columns.map(column => {
      const flex = get(column, 'flex') || 10
      return { ...column, flex }
    })

    const minWidthRemX = columnsX.reduce((acc, curr) => {
      return acc + curr.flex
    }, 0)

    setMinWidthRem(minWidthRemX)
  }, [columns])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: minHeight,
        width: '100%',
        overflowX: 'scroll'
      }}
    >
      <div
        style={{
          flexGrow: 1,
          minHeight: minHeight,
          minWidth: `${minWidthRem}rem`
        }}
      >
        <DataGrid
          className={classes.root}
          rows={rows}
          loading={loading}
          columns={columns}
          columnBuffer={columns.length}
          rowHeight={45}
          headerHeight={45}
          page={1}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 25, 50, rows.length]}
          hideFooterSelectedRowCount
          hideFooter={hideFooter}
          components={{
            pagination: Pagination
          }}
        />
      </div>
    </div>
  )
}

export default DataGridScroll
