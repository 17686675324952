import React, { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

type TooltipComponentType = {
  text: string
  children: JSX.Element
  enterDelay?: number
}

const TooltipComponent: React.FC<TooltipComponentType> = ({
  text,
  children,
  enterDelay = 100
}) => {
  const [open, setOpen] = useState(false)

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div
        onClick={() => setOpen(true)}
        onMouseEnter={
          enterDelay
            ? () => setTimeout(() => setOpen(true), enterDelay)
            : () => setOpen(true)
        }
        onMouseLeave={
          enterDelay
            ? () => setTimeout(() => setOpen(false), enterDelay)
            : () => setOpen(false)
        }
      >
        <Tooltip
          title={<div style={{ fontSize: '1rem' }}>{text}</div>}
          open={open}
        >
          {children}
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}

export default TooltipComponent
