import Yup, { validateYupSchema } from 'src/utils/yup'

const schema = Yup.object().shape({
  empresa: Yup.string().required().nullable(false),
  email: Yup.string().required().nullable(false)
})

export type ForgotPasswordInput = {
  empresa: string
  email: string
}

type ForgorPasswordValidationOutput = {
  empresa?: string
  email?: string
}

export const validate = async (
  values: ForgotPasswordInput
): Promise<ForgorPasswordValidationOutput> => {
  try {
    await validateYupSchema(schema, values)
    return {}
  } catch (err: any) {
    return err
  }
}
