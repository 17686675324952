// @ts-ignore
import XLSExport from 'xlsexport'
import { isNumber, set } from 'lodash'

const exportCSV = (data: any[], fileName: string) =>
  new XLSExport(data).exportToCSV(`${fileName}.csv`)

const exportXLSX = (data: any[], fileName: string) =>
  new XLSExport(data).exportToXLS(`${fileName}.xls`)

const parseNumbers = (data: any[]) => {
  for (const line of data) {
    Object.keys(line).forEach(key => {
      if (isNumber(line[key]) && isFinite(line[key])) {
        set(line, key, Number(line[key]).toLocaleString('pt-BR'))
      }
    })
  }

  return data
}

export async function handleExport(
  data: any[],
  fileExtension = 'xls',
  fileName = 'dados'
): Promise<void> {
  try {
    const parsed = parseNumbers(data)

    switch (fileExtension) {
      case 'csv':
        exportCSV(parsed, fileName)
        break
      case 'xls':
        exportXLSX(parsed, fileName)
        break
      default:
        console.warn(`TODO, export ${fileExtension}`)
    }
  } catch (err) {
    console.log('handleExport error', err, JSON.stringify(err, null, 2))
  }
}
