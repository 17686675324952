import { InputAdornment, IconButton } from '@material-ui/core'
import { VisibilityOffRounded, VisibilityRounded } from '@material-ui/icons'
import React from 'react'

type VisibleAdornmentType = {
  visible: boolean
  onClick: () => void
}

const VisibleAdornment: React.FC<VisibleAdornmentType> = ({
  visible,
  onClick
}) => (
  <InputAdornment position="end">
    <IconButton onClick={() => onClick()} size={'small'}>
      {visible ? (
        <VisibilityOffRounded style={{ color: 'lightgrey' }} />
      ) : (
        <VisibilityRounded style={{ color: 'lightgrey' }} />
      )}
    </IconButton>
  </InputAdornment>
)

export default VisibleAdornment
