import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Pagination } from '@material-ui/lab'

import SingleSelect from 'src/components/inputs/SingleSelect'
import TextFieldComponent from 'src/components/inputs/TextField'
import { uniq } from 'lodash'

const TablePaginationWrappper = styled('div')`
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 5rem;
`

const FieldWrapper = styled('div')`
  width: 20rem;
`

type PaginationProps = {
  index: number
  onChangeIndex: (value: number) => void
  maxIndex: number
  itemsPerPage: number
  onChangeItemsPerPage: (value: number) => void
  itemsPerPageOptions: number[]
}

const TablePagination: React.FC<PaginationProps> = ({
  index,
  onChangeIndex,
  maxIndex,
  itemsPerPage,
  onChangeItemsPerPage,
  itemsPerPageOptions = []
}) => {
  const [options, setOptions] = useState<number[]>([])

  useEffect(() => {
    const newOptions = uniq([itemsPerPage, ...itemsPerPageOptions])
      .filter(value => value <= maxIndex)
      .sort(function (a, b) {
        return a - b
      })
    setOptions(newOptions)
  }, [itemsPerPage, itemsPerPageOptions])

  return (
    <TablePaginationWrappper>
      <FieldWrapper>
        <SingleSelect
          label={'Itens por página'}
          value={itemsPerPage}
          onChange={onChangeItemsPerPage}
          options={options.map(a => ({
            label: String(a),
            value: a
          }))}
        />
      </FieldWrapper>

      <Pagination
        count={Math.ceil(maxIndex / itemsPerPage)}
        page={index}
        onChange={(e, page: number) => onChangeIndex(Number(page))}
      />

      <FieldWrapper>
        <TextFieldComponent
          value={index}
          onChange={onChangeIndex}
          label="Página"
        />
      </FieldWrapper>
    </TablePaginationWrappper>
  )
}

export default TablePagination
