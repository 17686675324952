import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import { colorSecondary } from 'src/theme/theme'

const DecisionHeaderButton = styled(Button)`
  height: inherit;
  border-bottom: inset !important;
  border-bottom-color: ${props =>
    // @ts-ignore
    props.selected ? colorSecondary : 'transparent'} !important;
  text-transform: capitalize !important;
  color: white !important;
  margin: 0 0.5rem !important;
  border-radius: 0 !important;
  border-bottom-width: 0.2rem !important;
`

export default DecisionHeaderButton
