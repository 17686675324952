import { setLocale } from 'yup'

import * as Yup from 'yup'
import { formatDate } from './dates'

type TypeLabel = {
  type: string
  label: string
}

setLocale({
  mixed: {
    default: 'Não é válido',
    // @ts-ignore
    required: function required({ label }: TypeLabel) {
      if (label) {
        return `Campo "${label}" é obrigatório`
      }
      return 'Campo obrigatório'
    },
    notType: function notType({ type, label }: TypeLabel) {
      switch (type) {
        case 'number':
          return label ? `"${label}" deve ser um número` : 'Deve ser um número'
        case 'string':
          return label
            ? `"${label}" deve ser preenchido`
            : 'Deve ser preenchido'
        case 'date':
          return label ? `"${label}" deve ser uma data válida` : 'Data inválida'
        case 'email':
          return label
            ? `"${label}" deve ser um email válido`
            : 'Email inválido'
        default:
          return label ? `"${label}" tipo inválido` : 'Tipo inválido'
      }
    }
  },
  date: {
    max: (e: any) => {
      return `Data deve ser menor ou igual a ${formatDate(e.max)}`
    },
    min: (e: any) => {
      return `Data deve ser maior ou igual a ${formatDate(e.min)}`
    }
  }
})

export async function validateYupSchema(
  schema: any,
  data: any,
  options = { abortEarly: false }
): Promise<any> {
  try {
    return await schema.validate(data, options)
  } catch (err) {
    throw handleYupErrors(err)
  }
}

export function handleYupErrors(e: Yup.ValidationError): ValidationErrors {
  const validationErrors = {}

  for (const { path, message } of e.inner) {
    // @ts-ignore
    validationErrors[path] = message
  }

  return validationErrors
}

interface ValidationErrors {
  [field: string]: string
}

export default Yup
