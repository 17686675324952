import React, { useState } from 'react'
import { Form } from 'react-final-form'
import VisibleAdornment from 'src/components/Adornments/VisibilityAdornement'
import Divider, { proportionallyTo } from 'src/components/Divider/Divider'
import DecisionField from 'src/components/Forms/Field'
import TextField from 'src/components/inputs/TextField'
import {
  PageTitle,
  PagePaper,
  ContentHeader,
  PageFooter
} from 'src/pages/utils'
import useDecisionSnackbar from 'src/hooks/useSnackbar'
import { Button } from '@material-ui/core'
import client from 'src/config/apolloClient'
import { UPDATE_PASSWORD_WITH_OLD } from 'src/apollo/usuario/mutations'
import { get } from 'lodash'
import { UpdatePasswordInput, validate } from './validation'

const Configuracoes = (): JSX.Element => {
  const [oldPasswordVisible, setOldPasswordVisible] = useState<boolean>(false)
  const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false)
  const [
    newPasswordConfirmVisible,
    setNewPasswordConfirmVisible
  ] = useState<boolean>(false)

  const {
    openInfoSnackbar,
    openSuccessSnackbar,
    openErrorSnackbar
  } = useDecisionSnackbar()

  const onSubmit = async (values: UpdatePasswordInput) => {
    openInfoSnackbar('Salvando')

    try {
      const response = await client.mutate({
        mutation: UPDATE_PASSWORD_WITH_OLD,
        variables: values
      })

      const success = get(
        response,
        'data.portalUserUpdatePasswordWithOld.success'
      )
      if (success) {
        const message = get(
          response,
          'data.portalUserUpdatePasswordWithOld.successMessage'
        )
        openSuccessSnackbar(message)
      } else {
        const message = get(
          response,
          'data.portalUserUpdatePasswordWithOld.errorMessage'
        )
        openErrorSnackbar(message)
      }
    } catch (err) {
      openErrorSnackbar()
    }
  }

  return (
    <>
      <PageTitle>CONFIGURAÇÕES</PageTitle>
      <Form
        validate={validate}
        onSubmit={onSubmit}
        render={({ handleSubmit, invalid }) => (
          <PagePaper>
            <ContentHeader>Alterar Senha</ContentHeader>

            <Divider
              widthPercentage={proportionallyTo([1, 1, 1])}
              breakPointPx={800}
            >
              <DecisionField
                name="oldPassword"
                render={props => (
                  <TextField
                    type={oldPasswordVisible ? undefined : 'password'}
                    label={'Senha Atual'}
                    {...props}
                    endAdornments={[
                      <VisibleAdornment
                        key={1}
                        visible={oldPasswordVisible}
                        onClick={() =>
                          setOldPasswordVisible(!oldPasswordVisible)
                        }
                      />
                    ]}
                  />
                )}
              />
              <DecisionField
                name="newPassword"
                render={props => (
                  <TextField
                    type={newPasswordVisible ? undefined : 'password'}
                    label={'Nova Senha'}
                    {...props}
                    endAdornments={[
                      <VisibleAdornment
                        key={1}
                        visible={newPasswordVisible}
                        onClick={() =>
                          setNewPasswordVisible(!newPasswordVisible)
                        }
                      />
                    ]}
                  />
                )}
              />
              <DecisionField
                name="newPasswordConfirm"
                render={props => (
                  <TextField
                    type={newPasswordConfirmVisible ? undefined : 'password'}
                    label={'Confirmar Nova Senha'}
                    {...props}
                    endAdornments={[
                      <VisibleAdornment
                        key={1}
                        visible={newPasswordConfirmVisible}
                        onClick={() =>
                          setNewPasswordConfirmVisible(
                            !newPasswordConfirmVisible
                          )
                        }
                      />
                    ]}
                  />
                )}
              />
            </Divider>

            <PageFooter>
              <Button
                color={'primary'}
                variant={'contained'}
                disabled={invalid}
                onClick={handleSubmit}
              >
                SALVAR
              </Button>
            </PageFooter>
          </PagePaper>
        )}
      />
    </>
  )
}

export default Configuracoes
