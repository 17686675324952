import React, { useState } from 'react'
import { get } from 'lodash'
import { Button, IconButton, FormHelperText } from '@material-ui/core'
import {
  CloudDownloadRounded,
  DeleteRounded,
  SaveRounded,
  EditRounded,
  CloseRounded
} from '@material-ui/icons'

import TextFieldComponent from 'src/components/inputs/TextField'
import useDecisionSnackbar from 'src/hooks/useSnackbar'
import InfoDialog from 'src/components/Dialogs/InfoDialog'
import client, { refetchQueryByName } from 'src/config/apolloClient'
import DropzoneComponent from 'src/components/Dropzone/Dropzone'
import {
  ADD_NOTA_FISCAL,
  UPDATE_NOTA_FISCAL,
  REMOVE_NOTA_FISCAL
} from 'src/apollo/usuario/mutations'
import DataGridScroll from 'src/components/DataGrid/DataGridScroll'
import DecisionField from 'src/components/Forms/Field'
import { Form } from 'react-final-form'

import {
  DecisionDialog,
  DecisionDialogBody
} from 'src/components/Dialogs/utils'
import { ButtonStyled } from 'src/components/DataGrid/utils'
import Divider, { proportionallyTo } from 'src/components/Divider/Divider'
import styled from '@emotion/styled'
import { validateNotaFiscalAnexo } from './validation'

// NFS-e
export const NotaFiscalButton: React.FC = props => {
  const faturamentoId = parseInt(get(props, 'value.id'))
  const notaFiscal = get(props, 'value.notaFiscal')
  const notaFiscalTerceiro = get(props, 'value.notaFiscalTerceiro')

  const [showFaturaDialog, setShowFaturaDialog] = useState<boolean>(false)
  // const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false)

  return faturamentoId ? (
    <>
      {/* <InfoDialog
        open={showWarningDialog}
        onClose={() => setShowWarningDialog(false)}
        title={'Atenção'}
        content={
          'Clique no ícone "lápis" ao lado do campo "Nro Nota Fiscal" e informe o número. Após, clique no ícone "disquete" para salvar a informação.'
        }
      /> */}

      <InfoDialog
        fullWidth
        maxWidth={'md'}
        title={'Anexo de NFS-e'}
        open={showFaturaDialog}
        content={
          notaFiscal ? (
            <NotaFiscalTable
              faturamentoId={faturamentoId}
              notaFiscal={notaFiscal}
              notaFiscalTerceiro={notaFiscalTerceiro}
            />
          ) : (
            <NotaFiscalDropzone
              faturamentoId={faturamentoId}
            />
          )
        }
        onClose={() => setShowFaturaDialog(false)}
      />

      <ButtonStyled
        color={'secondary'}
        variant={'contained'}
        onClick={() => setShowFaturaDialog(true)}
      >
        NFS-e
      </ButtonStyled>
    </>
  ) : (
    <div />
  )
}

type NotaFiscalTextFieldProps = {
  isEditing: boolean
  setIsEditing: (value: boolean) => void
  handleSubmit: any
}

const NotaFiscalTextField: React.FC<NotaFiscalTextFieldProps> = ({
  isEditing,
  setIsEditing,
  handleSubmit
}) => {
  return (
    <DecisionField
      name={'notaFiscalTerceiro'}
      render={p => (
        <TextFieldComponent
          {...p}
          endAdornments={[
            <IconButton
              key={0}
              size={'small'}
              onClick={() => {
                if (isEditing) {
                  setIsEditing(false)
                  handleSubmit()
                } else {
                  setIsEditing(true)
                }
              }}
            >
              {isEditing ? <SaveRounded /> : <EditRounded />}
            </IconButton>
          ]}
        />
      )}
    />
  )
}

type NotaFiscalTableType = {
  faturamentoId: number
  notaFiscal: any
  notaFiscalTerceiro: string
}

const NotaFiscalTable: React.FC<NotaFiscalTableType> = ({
  faturamentoId,
  notaFiscal,
  notaFiscalTerceiro
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const { openSuccessSnackbar, openErrorSnackbar } = useDecisionSnackbar()

  const [submitting, setSubmitting] = useState(false)

  const handleDeleteNotaFiscal = async () => {
    try {
      setSubmitting(true)

      const response = await client.mutate({
        mutation: REMOVE_NOTA_FISCAL,
        variables: { id: faturamentoId },
        refetchQueries: ['faturamentosUnionEntradasSaidasComplementares']
      })

      const success = get(
        response,
        'data.portalUsuarioFaturamento.removeNotaFiscal.success'
      )

      if (success) {
        openSuccessSnackbar('Removido com sucesso')
      } else {
        const error = get(
          response,
          'data.portalUsuarioFaturamento.removeNotaFiscal.error'
        )
        openErrorSnackbar(error)
      }
    } catch (err) {
      openErrorSnackbar()
    } finally {
      setSubmitting(false)
    }
  }

  const handleUpdateNotaFiscal = async (values: any) => {
    try {
      setSubmitting(true)
      const response = await client.mutate({
        mutation: UPDATE_NOTA_FISCAL,
        variables: {
          id: faturamentoId,
          data: values
        },
        refetchQueries: ['faturamentosUnionEntradasSaidasComplementares']
      })

      const success = get(
        response,
        'data.portalUsuarioFaturamento.update.success'
      )

      if (success) {
        openSuccessSnackbar('Salvo com sucesso')
      } else {
        const error = get(
          response,
          'data.portalUsuarioFaturamento.update.error'
        )
        openErrorSnackbar(error)

        const fieldErrors =
          get(response, 'data.portalUsuarioFaturamento.update.fieldErrors') ||
          {}

        return fieldErrors
      }
    } catch (err) {
      openErrorSnackbar()
    } finally {
      setSubmitting(false)
    }
  }

  const columns = [
    {
      field: 'id',
      headerName: ' ',
      flex: 2,
      renderCell: () => {
        return (
          <IconButton onClick={() => handleDeleteNotaFiscal()}>
            <DeleteRounded />
          </IconButton>
        )
      }
    },
    {
      field: 'name',
      headerName: 'Nome',
      flex: 15
    },
    {
      field: 'notaFiscalTerceiro',
      headerName: 'Nro Nota Fiscal',
      flex: 10,
      renderCell: () => (
        <NotaFiscalTextField
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          handleSubmit={() => {}}
        />
      )
    },
    {
      field: 'url',
      headerName: 'Baixar',
      flex: 3,
      renderCell: ({ value }: any) => {
        return (
          <IconButton>
            <a href={value} rel="noreferrer" target={'_blank'}>
              <CloudDownloadRounded />
            </a>
          </IconButton>
        )
      }
    }
  ]

  return (
    <div>
      <Form
        keepDirtyOnReinitialize
        onSubmit={handleUpdateNotaFiscal}
        initialValues={{ notaFiscalTerceiro }}
        render={({ handleSubmit }) => {
          return (
            <>
              <DecisionDialog
                open={isEditing}
                onClose={() => setIsEditing(false)}
              >
                <DecisionDialogBody>
                  <NotaFiscalTextField
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    handleSubmit={handleSubmit}
                  />
                </DecisionDialogBody>
              </DecisionDialog>

              <DataGridScroll
                hideFooter={true}
                minHeight={'15rem'}
                rows={[{ id: faturamentoId, notaFiscalTerceiro, ...notaFiscal }]}
                loading={submitting}
                columns={columns}
              />
            </>
          )
        }}
      />
    </div>
  )
}

type NotaFiscalDropzoneType = {
  faturamentoId: number
}

const NotaFiscalDropzone: React.FC<NotaFiscalDropzoneType> = ({
  faturamentoId
}) => {
  const { openSuccessSnackbar, openErrorSnackbar } = useDecisionSnackbar()

  const [submitting, setSubmitting] = useState(false)

  const onSubmit = async (values: any) => {
    const { anexo, notaFiscalTerceiro } = values

    try {
      setSubmitting(true)

      try {
        const response = await client.mutate({
          mutation: ADD_NOTA_FISCAL,
          variables: {
            id: faturamentoId,
            file: anexo
          },
          refetchQueries: ['faturamentosUnionEntradasSaidasComplementares']
        })
        const success = get(
          response,
          'data.portalUsuarioFaturamento.addNotaFiscal.success'
        )
        if (success) {
          openSuccessSnackbar('Anexado com sucesso')
        } else {
          const error = get(
            response,
            'data.portalUsuarioFaturamento.addNotaFiscal.error'
          )
          openErrorSnackbar(error)
        }
      } catch (err) {
        openErrorSnackbar('Erro ao anexar nota fiscal')
      }

      try {
        setSubmitting(true)
        const response = await client.mutate({
          mutation: UPDATE_NOTA_FISCAL,
          variables: {
            id: faturamentoId,
            data: {
              notaFiscalTerceiro: notaFiscalTerceiro
            }
          }
          // refetchQueries: ['faturas']
        })

        const success = get(
          response,
          'data.portalUsuarioFaturamento.update.success'
        )

        if (success) {
          openSuccessSnackbar('Salvo com sucesso')
        } else {
          const error = get(
            response,
            'data.portalUsuarioFaturamento.update.error'
          )
          openErrorSnackbar(error)

          const fieldErrors =
            get(response, 'data.portalUsuarioFaturamento.update.fieldErrors') ||
            {}

          return fieldErrors
        }
      } catch (err) {
        openErrorSnackbar('Erro ao editar número da nota fiscal')
      }
    } finally {
      setSubmitting(false)
      refetchQueryByName('faturas')
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateNotaFiscalAnexo}
      render={({ handleSubmit }) => {
        return (
          <NotaFiscalDropzoneWrapper>
            {/* @ts-ignore */}
            <Divider
              widthPercentage={proportionallyTo([1, 1])}
              breakPointPx={800}
            >
              <DecisionField
                name={'notaFiscalTerceiro'}
                label={'Nr Nota Fiscal'}
                render={props => <TextFieldComponent {...props} />}
              />
            </Divider>

            <DecisionField
              name={'anexo'}
              render={props => {
                return props.value ? (
                  <div>
                    <AnexoToBeSubmittedWrapper>
                      {get(props, 'value.name')}

                      <IconButton onClick={() => props.onChange(null)}>
                        <CloseRounded />
                      </IconButton>
                    </AnexoToBeSubmittedWrapper>
                    {props.error && (
                      <FormHelperText error>{props.error}</FormHelperText>
                    )}
                  </div>
                ) : (
                  <div>
                    <DropzoneComponent
                      fallbackWarning={'Problema'}
                      loading={submitting}
                      onSave={async (files: any) => {
                        const file = get(files, '[0]')
                        props.onChange(file)
                      }}
                    />
                    {props.error && (
                      <FormHelperText error>{props.error}</FormHelperText>
                    )}
                  </div>
                )
              }}
            />

            <div>
              <Button
                color={'primary'}
                variant={'contained'}
                onClick={handleSubmit}
              >
                SALVAR
              </Button>
            </div>
          </NotaFiscalDropzoneWrapper>
        )
      }}
    />
  )
}

const NotaFiscalDropzoneWrapper = styled('div')`
  display: grid;

  grid-template-columns: 1fr;

  grid-gap: 2rem;
`

const AnexoToBeSubmittedWrapper = styled('div')``
