import { format, isValid } from 'date-fns'
import { get, set } from 'lodash'

// ex: '2020-10-10' -> '10/10/2020'
export function formatMySQLDate(mysqlDateString: string): string {
  try {
    const [yyyy, mm, dd] = mysqlDateString.split(' ')[0].split('-')

    if (isValid(new Date(Number(yyyy), Number(mm), Number(dd)))) {
      return `${dd}/${mm}/${yyyy}`
    } else {
      throw new Error('Invalid')
    }
  } catch (err) {
    return ''
  }
}

// ex: new Date() => 'yyyy-mm-dd'
export function formatDateString(date: Date): string {
  if (isValid(new Date(date))) {
    return format(new Date(date), 'yyyy-MM-dd')
  } else {
    return ''
  }
}

export function formatDateStringOnObject(obj: any, keys: string[]) {
  keys.forEach(key => {
    const value = get(obj, key)
    value && set(obj, key, formatDateString(value))
  })

  return obj
}

// ex: new Date() => 'dd/mm/yyyy'
export function formatDate(date: Date): string {
  if (isValid(new Date(date))) {
    return format(new Date(date), 'dd/MM/yyyy')
  } else {
    return ''
  }
}
