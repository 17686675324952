import styled from '@emotion/styled'
import { Button } from '@material-ui/core'

export const ButtonStyled = styled(Button)`
  padding: 0 1rem !important;
  width: fit-content;
  height: 2.5rem;
  background-color: #f9f9f9 !important;

  text-transform: capitalize !important;
`
