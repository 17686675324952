import React, { useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import TextField from 'src/components/inputs/TextField'
import DecisionField from 'src/components/Forms/Field'
import client from 'src/config/apolloClient'
import { LOGIN } from 'src/apollo/usuario/mutations'
import { Button, IconButton, InputAdornment } from '@material-ui/core'
import { get } from 'lodash'
import useDecisionSnackbar from 'src/hooks/useSnackbar'
import {
  AccountCircleRounded,
  LockRounded,
  VisibilityOffRounded,
  VisibilityRounded
} from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { validate, LoginInput } from './validation'
import { FieldsWrapper, ButtonsWrapper, LoginWrapper } from './LoginWrapper'
import queryString from 'query-string'

const LoginPortal = (): JSX.Element => {
  const [visiblePassword, setVisiblePassword] = useState(false)

  const history = useHistory()

  const { openSuccessSnackbar, openErrorSnackbar } = useDecisionSnackbar()

  const search = get(history, 'location.search') || ''
  const obj = queryString.parse(search)
  const { empresa: queryStringEmpresa } = obj

  const handleSubmit = async (values: LoginInput) => {
    try {
      const response = await client.mutate({
        mutation: LOGIN,
        variables: values
      })

      const success = get(response, 'data.loginPortal.success')

      if (success) {
        const token = get(response, 'data.loginPortal.token')
        localStorage.setItem('token', token)
        localStorage.setItem('empresa', values.empresa)
        localStorage.setItem('email', values.email)
        openSuccessSnackbar('Logado com sucesso')
        history.push('/faturas')
      } else {
        const error = get(response, 'data.loginPortal.error')

        openErrorSnackbar(error || 'Empresa, E-mail ou senha incorretos.')
      }
    } catch (err) {
      openErrorSnackbar('Erro')
    }
  }

  return (
    <Form
      initialValues={{
        empresa: queryStringEmpresa || localStorage.getItem('empresa'),
        email: localStorage.getItem('email')
      }}
      validate={validate}
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <LoginWrapper>
          <FieldsWrapper>
            <DecisionField
              name={'empresa'}
              render={props => <TextField label={'Empresa'} {...props} />}
            />
            <DecisionField
              name={'email'}
              render={props => (
                <TextField
                  endAdornments={[
                    <InputAdornment key={1} position="end">
                      <AccountCircleRounded style={{ color: 'lightgrey' }} />
                    </InputAdornment>
                  ]}
                  label={'E-mail'}
                  {...props}
                />
              )}
            />
            <DecisionField
              name={'senha'}
              render={props => (
                <TextField
                  type={visiblePassword ? undefined : 'password'}
                  endAdornments={[
                    <InputAdornment key={1} position="start">
                      <LockRounded style={{ color: 'lightgrey' }} />
                    </InputAdornment>,
                    <InputAdornment key={2} position="end">
                      <IconButton
                        onClick={() => setVisiblePassword(!visiblePassword)}
                        size={'small'}
                      >
                        {visiblePassword ? (
                          <VisibilityOffRounded
                            style={{ color: 'lightgrey' }}
                          />
                        ) : (
                          <VisibilityRounded style={{ color: 'lightgrey' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ]}
                  label={'Senha'}
                  {...props}
                />
              )}
            />
            <ButtonsWrapper>
              <FormSpy subscription={{ invalid: true }}>
                {({ invalid }) => (
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    disabled={invalid}
                    onClick={handleSubmit}
                  >
                    ENTRAR
                  </Button>
                )}
              </FormSpy>

              <a href="#" onClick={() => history.push('/esqueci-minha-senha')}>
                Esqueci minha senha
              </a>
            </ButtonsWrapper>
          </FieldsWrapper>
        </LoginWrapper>
      )}
    />
  )
}

export default LoginPortal
