import React from 'react'

import { useSnackbar } from 'notistack'
import DecisionIconButton from 'src/components/Buttons/IconButton'
import { CloseRounded } from '@material-ui/icons'

const useDecisionSnackbar = (): any => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const action = (key: number) => (
    <DecisionIconButton size={'small'} onClick={() => closeSnackbar(key)}>
      <CloseRounded style={{ color: 'white' }} />
    </DecisionIconButton>
  )
  const openSuccessSnackbar = (message: any = 'Salvo com sucesso') => {
    enqueueSnackbar(message, { variant: 'success' })
  }

  const openErrorSnackbar = (message: any = 'Algo deu errado') => {
    return enqueueSnackbar(message, { variant: 'error' })
  }

  const openWarningSnackbar = (message: any) => {
    return enqueueSnackbar(message, { variant: 'warning' })
  }

  const openInfoSnackbar = (message: any) => {
    return enqueueSnackbar(message, { variant: 'info' })
  }

  const openPersistSuccessSnackbar = (message: any = 'Salvo com sucesso') => {
    enqueueSnackbar(message, { persist: true, variant: 'success', action })
  }

  const openPersistErrorSnackbar = (message: any = 'Algo deu errado') => {
    return enqueueSnackbar(message, { persist: true, variant: 'error', action })
  }

  const openPersistWarningSnackbar = (message: any) => {
    return enqueueSnackbar(message, {
      persist: true,
      variant: 'warning',
      action
    })
  }

  const openPersistInfoSnackbar = (message: any) => {
    return enqueueSnackbar(message, { persist: true, variant: 'info', action })
  }

  return {
    openSuccessSnackbar,
    openErrorSnackbar,
    openWarningSnackbar,
    openInfoSnackbar,

    openPersistSuccessSnackbar,
    openPersistErrorSnackbar,
    openPersistWarningSnackbar,
    openPersistInfoSnackbar
  }
}

export default useDecisionSnackbar
