import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { get } from 'lodash'

export type ColumnConfig = {
  name: string
  label: string
  minRem: number
  // columnFilter:
  cellFormatter: (value: any) => any
}

export type BodyProps = {
  config: ColumnConfig[]
  rows: any[]
}

export type HeaderProps = {
  config: ColumnConfig[]
}

export const TableRowStyled = styled(TableRow)`
  display: flex !important;
  width: 100% !important;
`

export const TableCellStyled = styled(TableCell)`
  min-width: ${(props: any) => {
    const width = get(props, 'a') || '1'
    return `${width}rem`
  }};

  flex-grow: ${(props: any) => {
    const width = get(props, 'a') || '1'
    return width
  }};
`

export const TableWrapper = styled(Table)`
  /* display: flex !important;
  width: 100% !important; */

  overflow-x: auto;

  flex-grow: 1;
`

export const TableHeadStyled = styled(TableHead)`
  display: flex !important;

  width: 100% !important;
`

export const TableBodyStyled = styled(TableBody)`
  display: flex !important;
  width: 100% !important;
  flex-direction: column;
  flex-grow: 1;
`

type PaginationPageIdsType = {
  ids: number[]
  pageIndex: number
  itemsPerPage: number
  children: (a: any) => any
}

export const PaginationPageIds: React.FC<PaginationPageIdsType> = ({
  ids,
  pageIndex,
  itemsPerPage,
  children
}) => {
  const [pageIds, setPageIds] = useState<number[]>([])

  useEffect(() => {
    const newPageIds = ids.slice(
      pageIndex * itemsPerPage,
      pageIndex * itemsPerPage + itemsPerPage
    )
    setPageIds(newPageIds)
  }, [ids.join(','), pageIndex, itemsPerPage])

  return children({ pageIds })
}

type PaginationType = {
  initialItemsPerPage: number
  resetProps: any[]
  children: (a: any) => any
}

export const Pagination: React.FC<PaginationType> = ({
  initialItemsPerPage = 15,
  resetProps = [],
  children
}) => {
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(initialItemsPerPage)

  useEffect(() => {
    setPageIndex(0)
    // @ts-ignore
  }, [...resetProps, itemsPerPage])

  return children({ pageIndex, setPageIndex, itemsPerPage, setItemsPerPage })
}
