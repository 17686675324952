import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, NextLink, Operation } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'
import { get } from 'lodash'
import { history } from 'src/routes/history'

const config = {
  apiBaseUrl: getBaseURL()
}

function getBaseURL() {
  // return 'https://backend-test-dot-decision-systems-230418.rj.r.appspot.com'
  if (process.env.REACT_APP_BACKEND_ENV === 'production') {
    return 'https://decision-backend-prod-orqbkiu2ma-rj.a.run.app'
  }
  if (process.env.REACT_APP_BACKEND_ENV === 'test') {
    return 'https://decision-backend-test-orqbkiu2ma-rj.a.run.app'
  }
  return 'http://127.0.0.1:3000'
}

const authLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  const token = localStorage.getItem('token')
  operation.setContext({
    headers: {
      'x-auth': token
    }
  })
  return forward(operation)
})

const errorLink = onError(error => {
  const { graphQLErrors } = error
  if (graphQLErrors) {
    graphQLErrors.forEach(graphQLError => {
      const code = get(graphQLError, 'extensions.code')
      if (code === 'UNAUTHENTICATED') {
        localStorage.removeItem('token')
        history.push('/login')
      }
    })
  }
})

const uri = `${config.apiBaseUrl}/graphql`

const client = new ApolloClient({
  // @ts-ignore
  link: ApolloLink.from([authLink, errorLink, createUploadLink({ uri })]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    }
  }
})

const findQueries = (manager: any, name: string) => {
  const matching: any[] = []
  manager.queries.forEach((q: any) => {
    if (q.observableQuery && q.observableQuery.queryName === name) {
      matching.push(q)
    }
  })
  return matching
}

export const refetchQueryByName = (name: string) => {
  return Promise.all(
    findQueries(client.queryManager, name).map(q => q.observableQuery.refetch())
  )
}

export default client
