import { chunk } from 'lodash'

export function reduceOnlyNotNull(data: any): any {
  return Object.keys(data).reduce(
    (acc, field) =>
      data[field] !== null ? { ...acc, [field]: data[field] } : acc,
    {}
  )
}

export function reduceOnlyDefined(data: any): any {
  return Object.keys(data).reduce(
    (acc, field) =>
      data[field] !== undefined ? { ...acc, [field]: data[field] } : acc,
    {}
  )
}

export function removeEmptyArrays(data: any): any {
  return Object.keys(data).reduce((acc, value) => {
    if (
      (Array.isArray(data[value]) && data[value].length > 0) ||
      !Array.isArray(data[value])
    ) {
      return { ...acc, [value]: data[value] }
    }

    return acc
  }, {})
}

export const sequentialBatchMapAsync: any = async (
  batchSize = 1,
  items: any[] = [],
  func: (x: any[], index: number, array: any[]) => Promise<any[]>
) => {
  const chunkedItems = chunk(items, batchSize)

  // @ts-ignore
  return await chunkedItems.reduce(async (acc, curr, index, array) => {
    return [...(await acc), ...(await func(curr, index, array))]
  }, Promise.resolve([]))
}

export const sequentialBatchLimitOffsetAsync: any = async (
  batchSize = 1,
  maxItens: number,
  func: (
    limit: number,
    offset: number,
    index: number,
    array: any[]
  ) => Promise<any[]>
) => {
  const batches = []
  for (let i = 0; i < maxItens / batchSize; i++) {
    batches.push({ limit: batchSize, offset: i })
  }

  // @ts-ignore
  return await batches.reduce(async (acc, { limit, offset }, index, array) => {
    return [...(await acc), ...(await func(limit, offset, index, array))]
  }, Promise.resolve([]))
}
