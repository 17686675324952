import React from 'react'
import styled from '@emotion/styled'
import TablePagination from 'src/components/Tables/utils/TablePagination'

const PaginationWrapper = styled('div')`
  width: 100%;
  border-top: solid 1px lightgrey;
`
export const Pagination = ({
  pagination,
  api: { current },
  options
}: any): JSX.Element => {
  const { setPage, setPageSize } = current
  const { page, pageSize, rowCount } = pagination
  const { rowsPerPageOptions } = options

  return (
    <PaginationWrapper>
      <TablePagination
        index={page}
        onChangeIndex={setPage}
        maxIndex={rowCount}
        itemsPerPage={pageSize}
        onChangeItemsPerPage={value => {
          setPage(1)
          setPageSize(value)
        }}
        itemsPerPageOptions={rowsPerPageOptions}
      />
    </PaginationWrapper>
  )
}
