import Yup, { validateYupSchema } from 'src/utils/yup'

const schema = Yup.object().shape({
  empresa: Yup.string().required().nullable(false),
  email: Yup.string().required().nullable(false),
  senha: Yup.string().required().nullable(false)
})

export type LoginInput = {
  empresa: string
  email: string
  senha: string
}

type LoginValidationOutput = {
  empresa?: string
  email?: string
  senha?: string
}

export const validate = async (
  values: LoginInput
): Promise<LoginValidationOutput> => {
  try {
    await validateYupSchema(schema, values)
    return {}
  } catch (err: any) {
    return err
  }
}
