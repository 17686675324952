import { get } from 'lodash'
import Yup, { validateYupSchema } from 'src/utils/yup'
import { unflatten } from 'flat'
import { addMonths, isValid } from 'date-fns'

function datesGreaterThanChanges(value: any) {
  if (value) {
    // @ts-ignore
    return this.required()
  }
}

function datesLessThanChanges(value: any) {
  if (value && isValid(value)) {
    // @ts-ignore
    return this.max(addMonths(new Date(value), 2))
      .min(new Date(value))
      .required()
  }
}

const schema = Yup.object().shape({
  baixa: Yup.object().shape(
    {
      dataBaixaFatura_gte: Yup.date()
        .nullable(true)
        .when('dataBaixaFatura_lte', datesGreaterThanChanges),
      dataBaixaFatura_lte: Yup.date()
        .nullable(true)
        .when('dataBaixaFatura_gte', datesLessThanChanges)
    },
    // @ts-ignore
    ['dataBaixaFatura_lte', 'dataBaixaFatura_gte']
  ),
  emissao: Yup.object().shape(
    {
      dataEmissaoFatura_gte: Yup.date()
        .nullable(true)
        .when('dataEmissaoFatura_lte', datesGreaterThanChanges),
      dataEmissaoFatura_lte: Yup.date()
        .nullable(true)
        .when('dataEmissaoFatura_gte', datesLessThanChanges)
    },
    // @ts-ignore
    ['dataEmissaoFatura_lte', 'dataEmissaoFatura_gte']
  ),
  vencimento: Yup.object()
    .shape(
      {
        dataVencimentoFatura_gte: Yup.date()
          .nullable(true)
          .when('dataVencimentoFatura_lte', datesGreaterThanChanges),
        dataVencimentoFatura_lte: Yup.date()
          .nullable(true)
          .when('dataVencimentoFatura_gte', datesLessThanChanges)
      },
      // @ts-ignore
      ['dataVencimentoFatura_gte', 'dataVencimentoFatura_lte']
    )
    // @ts-ignore
    .when(['baixa', 'emissao'], function (baixa: any, emissao: any) {
      const dataBaixaFaturaGte = get(baixa, 'dataBaixaFatura_gte')
      const dataBaixaFaturaLte = get(baixa, 'dataBaixaFatura_lte')
      const dataEmissaoFaturaGte = get(emissao, 'dataEmissaoFatura_gte')
      const dataEmissaoFaturaLte = get(emissao, 'dataEmissaoFatura_lte')

      if (
        !dataBaixaFaturaGte &&
        !dataBaixaFaturaLte &&
        !dataEmissaoFaturaGte &&
        !dataEmissaoFaturaLte
      ) {
        // @ts-ignore
        return this.shape({
          dataVencimentoFatura_gte: Yup.date()
            .nullable(true)
            .required('Pelo menos um filtro deve ser preenchido'),
          dataVencimentoFatura_lte: Yup.date()
            .nullable(true)
            .required('Pelo menos um filtro deve ser preenchido')
        })
      }
    })
})

// @ts-ignore
export const validate = async (values: any): Promise<any> => {
  try {
    await validateYupSchema(schema, values)
    return {}
  } catch (err) {
    return unflatten(err)
  }
}
