import styled from '@emotion/styled'
import { Paper } from '@material-ui/core'

export const PageTitle = styled('div')`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #444444;
`

export const PagePaper = styled(Paper)`
  width: 100%;
  padding: 2rem 4rem;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const ContentHeader = styled('div')`
  width: 100%;

  font-size: 1.6rem;
  padding: 1rem 0;
  margin-bottom: 2rem;
`

export const PageFooter = styled('div')`
  margin-top: auto;
  padding-top: 4rem;
`
