import React from 'react'
import Routes from './routes/index'

import Favicon from 'react-favicon'

const App: React.FC = () => (
  <>
    <Favicon url={'/images/decision_systems_logo.png'} />
    <Routes />
  </>
)

export default App
