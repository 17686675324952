import React from 'react'
import styled from '@emotion/styled'
import { Paper } from '@material-ui/core'

export const LoginContainer = styled('div')`
  background: url('/images/login_background.png') no-repeat center center fixed;
  background-size: cover;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const FieldsWrapper = styled(Paper)`
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-gap: 1.5rem;
  padding: 4rem;
  max-width: 60rem;
  width: 60rem;
`

export const ButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
`

export const PoweredByWrapper = styled('div')`
  color: white;
  margin-top: 5rem;
`

export const DecisionSystemsImage = styled('img')`
  margin-top: 1rem;
`

type LoginWrapperType = {
  children: JSX.Element
}

export const LoginWrapper: React.FC<LoginWrapperType> = ({ children }) => (
  <LoginContainer>
    {children}
    <PoweredByWrapper>Powered By</PoweredByWrapper>

    <a
      style={{ fontSize: '1.2rem' }}
      href="https://decisionsystems.com.br/"
      target="_blank"
      rel="noreferrer"
    >
      <DecisionSystemsImage src={'/images/decision_systems.png'} />
    </a>
  </LoginContainer>
)

export const TitleCenter = styled('div')`
  font-size: 1.5rem;
  text-align: center;
`
