import React from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core'

type Option = {
  value: any
  label: string
}

type SingleSelectType = {
  value: any
  onChange: (value: any) => void
  label: string
  options: Option[]
}

const SingleSelect: React.FC<SingleSelectType> = ({
  value,
  onChange,
  label,
  options
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={e => onChange(e.target.value)}>
        {options.map(({ value, label }, index) => (
          <MenuItem key={index} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{''}</FormHelperText>
    </FormControl>
  )
}

export default SingleSelect
