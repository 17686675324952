import styled from '@emotion/styled'
import React from 'react'

const DividerStyled = styled('div')`
  display: grid;
  grid-template-columns: ${(props: any) =>
    props.widthPercentage || 'minmax(1px, 1fr) minmax(1px, 1fr)'};
  grid-gap: ${(props: any) => props.gridGap || '0 5rem'};
  padding: ${(props: any) => props.padding};

  @media screen and (max-width: ${(props: any) => props.breakPoint}px) {
    grid-template-columns: minmax(1px, 1fr);
  }
`

const Divider: React.FC<IDividerProps> = props => {
  let {
    children,
    breakPointPx,
    gridGap,
    padding,
    widthPercentage,
    style
  } = props

  if (!Array.isArray(children)) {
    children = [children]
  }
  children = children.filter(el => el)

  return children.length > 0 ? (
    <DividerStyled
      style={style}
      //  @ts-ignore
      breakPoint={breakPointPx}
      gridGap={gridGap}
      padding={padding}
      widthPercentage={widthPercentage}
    >
      {children}
    </DividerStyled>
  ) : null
}

interface IDividerProps {
  children: JSX.Element[]
  breakPointPx: string | number
  gridGap?: string
  padding?: string
  paddingLeft?: string
  paddingRight?: string
  widthPercentage?: string
  style?: any
}

// const DividerItemStyle = styled('div')`
//   grid-column: span ${(props: any) => props.span};
// `

// export const DividerItem = ({ span = 1, children }) => (
//   <DividerItemStyle span={span}>{children}</DividerItemStyle>
// )

export const proportionallyTo = (proportions: number[]): string => {
  return proportions.map(proportion => `minmax(1px, ${proportion}fr)`).join(' ')
}

export default Divider
