import React from 'react'

import { DialogProps } from '@material-ui/core'
import {
  DecisionDialog,
  DecisionDialogHeader,
  DecisionDialogHeaderTitle,
  DecisionDialogBody,
  DecisionDialogHeaderCloseIcon
} from 'src/components/Dialogs/utils'

interface IProps extends DialogProps {
  content: any
  fullWidth?: boolean
  icon?: string
  onClose: (event?: any, reason?: 'backdropClick' | 'escapeKeyDown') => void
  open: boolean
  title: string
  helpText?: string
}

const InfoDialog: React.FC<IProps> = ({
  content,
  fullWidth,
  icon,
  maxWidth,
  onClose,
  open,
  title
  // helpText
}) => (
  <DecisionDialog
    open={open}
    onClose={onClose}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
  >
    <>
      <DecisionDialogHeader>
        <>
          <DecisionDialogHeaderTitle
            icon={icon}
            title={title}
            // helpText={helpText}
          />
          {onClose && <DecisionDialogHeaderCloseIcon onClose={onClose} />}
        </>
      </DecisionDialogHeader>
      <DecisionDialogBody>{content}</DecisionDialogBody>
    </>
  </DecisionDialog>
)

export default InfoDialog
