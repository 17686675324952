import gql from 'graphql-tag'

export const UPDATE_FATURA = gql`
  mutation updateFatura($id: Int! $data: PortalFaturamentoMutationInput!) {
    portalUsuarioFaturamento(id: $id) {
        update(data: $data) {
            success
            error
        }
    }
  }
`