import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Faturas from 'src/pages/Faturas/Faturas'
import LoginPortal from 'src/pages/Login/LoginPortal'
import styled from '@emotion/styled'
import Header from 'src/components/Header/Header'
import Configuracao from 'src/pages/Configuracoes/Configuracoes'
import RedefinePassword from 'src/pages/Login/RedefinePassword'
import ForgotMyPassword from 'src/pages/Login/ForgotMyPassword'
import RelatoriosTable from 'src/pages/Relatorios/RelatorioTable'
import RelatorioExportacao from 'src/pages/Relatorios/RelatorioExportacao'

const Screen = styled('div')`
  background-color: #f9f9f9;
  max-width: 100vw;
  min-width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`

const Subscreen = styled('div')`
  padding: 2rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

const Routes: React.FC = () => (
  <Screen>
    <Switch>
      <Route exact path={'/login'} component={LoginPortal} />
      <Route exact path={'/redefinir-senha'} component={RedefinePassword} />
      <Route exact path={'/esqueci-minha-senha'} component={ForgotMyPassword} />
      <Route path={'/'} component={Portal} />
    </Switch>
  </Screen>
)

const Portal = () => (
  <>
    <Header />
    <Subscreen>
      <Switch>
        <Route exact path={'/configuracoes'} component={Configuracao} />
        <Route exact path={'/faturas'} component={Faturas} />

        <Route exact path={'/relatorios'} component={RelatoriosTable} />
        <Route exact path={'/relatorios/:id'} component={RelatorioExportacao} />

        <Redirect to={'/faturas'} />
      </Switch>
    </Subscreen>
  </>
)

export default Routes
