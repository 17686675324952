import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@material-ui/core'

import { Warning, Close, Error } from '@material-ui/icons'
import styled from '@emotion/styled'

const StyledDialogContent = styled(DialogContent)`
  padding: 0 2.4rem 2.4rem 2.4rem;
`

const StyledDialogTitle = styled(DialogTitle)`
  padding: 2.4rem 2.4rem 2rem;
`

const FlexDiv = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CenterDiv = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
`
const TitleContainer = styled('span')`
  font-size: 1.8rem;
  margin-right: 1rem;
  flex-grow: 1;
`

const IconButtonStyled = styled(IconButton)`
  margin-left: auto;
  color: 'orange';
`

const DialogContentTextStyled = styled(DialogContentText)`
  /* text-align: justify; */
`

type DecisionDialogType = {
  open: boolean
  onClose: () => void
  children: JSX.Element
  fullWidth?: any
  maxWidth?: any
}

export const DecisionDialog: React.FC<DecisionDialogType> = ({
  open,
  onClose,
  children,
  fullWidth,
  maxWidth
}: any): JSX.Element => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      {children}
    </Dialog>
  )
}

type DialogHeaderType = {
  children: JSX.Element
}

export const DecisionDialogHeader: React.FC<DialogHeaderType> = ({
  children
}: any) => {
  return (
    <StyledDialogTitle>
      <FlexDiv>{children}</FlexDiv>
    </StyledDialogTitle>
  )
}

type DialogHeaderTitleType = {
  icon?: string
  title: string
}

export const DecisionDialogHeaderTitle: React.FC<DialogHeaderTitleType> = ({
  icon,
  title
}: any) => {
  return (
    <>
      {(icon === 'error' && (
        <CenterDiv>
          <Error style={{ color: 'red', fontSize: '2.5rem' }} />
        </CenterDiv>
      )) ||
        (icon === 'warning' && (
          <CenterDiv>
            <Warning style={{ color: 'yellow', fontSize: '2.5rem' }} />
          </CenterDiv>
        )) ||
        null}
      {title && <TitleContainer>{title}</TitleContainer>}
    </>
  )
}

type DialogHeaderCloseIconType = {
  onClose: () => void
}

export const DecisionDialogHeaderCloseIcon: React.FC<DialogHeaderCloseIconType> = ({
  onClose
}: any) => (
  <IconButtonStyled onClick={onClose}>
    <Close />
  </IconButtonStyled>
)

type DialogBodyType = {
  children: JSX.Element
}

export const DecisionDialogBody: React.FC<DialogBodyType> = ({
  children
}: any) => {
  const formatString = () => {
    if (typeof children === 'string') {
      const lineBreaks = children.split(/\n/g)

      if (lineBreaks.length > 1) {
        return lineBreaks.map((line, i) => (
          <span key={i}>
            {line}
            <br />
          </span>
        ))
      }
    }

    return children
  }

  return (
    <StyledDialogContent>
      <DialogContentTextStyled>{formatString()}</DialogContentTextStyled>
    </StyledDialogContent>
  )
}
