import React, { useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import TextField from 'src/components/inputs/TextField'
import DecisionField from 'src/components/Forms/Field'
import client from 'src/config/apolloClient'
import { UPDATE_PASSWORD } from 'src/apollo/usuario/mutations'
import { Button } from '@material-ui/core'
import { get } from 'lodash'
import useDecisionSnackbar from 'src/hooks/useSnackbar'
import { useHistory } from 'react-router-dom'
import {
  FieldsWrapper,
  ButtonsWrapper,
  LoginWrapper,
  TitleCenter
} from './LoginWrapper'
import VisibleAdornment from 'src/components/Adornments/VisibilityAdornement'

import { validate } from './redefinePasswordValidation'
import { GET_USUARIO } from '../../apollo/usuario/queires'

const RedefinePassword = (): JSX.Element => {
  const [newPWvisiblePassword, setnewPWVisiblePassword] = useState(false)
  const [
    newPWConfirmvisiblePassword,
    setnewPWConfirmVisiblePassword
  ] = useState(false)

  const history = useHistory()

  const {
    openSuccessSnackbar,
    openErrorSnackbar,
    openWarningSnackbar
  } = useDecisionSnackbar()

  const handleSubmit = async ({ newPassword }: any) => {
    try {
      const search = get(history, 'location.search') || ''
      const token = get(search.split('token='), '[1]')

      if (token) {
        localStorage.setItem('token', token)

        const userResponse = await client.query({ query: GET_USUARIO })
        const email = get(userResponse, 'data.meuUsuarioPortal.email')
        const empresa = get(userResponse, 'data.meuUsuarioPortal.empresa')

        const response = await client.mutate({
          mutation: UPDATE_PASSWORD,
          variables: { newPassword }
        })

        const success = get(response, 'data.portalUserUpdatePassword.success')
        if (success) {
          const successMessage = get(
            response,
            'data.portalUserUpdatePassword.successMessage'
          )
          openSuccessSnackbar(successMessage)
          localStorage.setItem('email', email)
          localStorage.setItem('empresa', empresa)
          localStorage.removeItem('token')
          history.push('/login')
        } else {
          const errorMessage = get(
            response,
            'data.portalUserUpdatePassword.errorMessage'
          )
          openErrorSnackbar(errorMessage)
        }
      } else {
        openWarningSnackbar('Link inválido')
      }
    } catch (err) {
      openErrorSnackbar('Erro')
    }
  }

  return (
    <Form
      validate={validate}
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <LoginWrapper>
          <FieldsWrapper>
            <TitleCenter>Redefina sua Senha Abaixo</TitleCenter>
            <DecisionField
              name="newPassword"
              render={props => (
                <TextField
                  type={newPWvisiblePassword ? undefined : 'password'}
                  label={'Nova Senha'}
                  {...props}
                  endAdornments={[
                    <VisibleAdornment
                      key={1}
                      visible={newPWvisiblePassword}
                      onClick={() =>
                        setnewPWVisiblePassword(!newPWvisiblePassword)
                      }
                    />
                  ]}
                />
              )}
            />

            <DecisionField
              name="newPasswordConfirm"
              render={props => (
                <TextField
                  type={newPWConfirmvisiblePassword ? undefined : 'password'}
                  label={'Confirmar Nova Senha'}
                  {...props}
                  endAdornments={[
                    <VisibleAdornment
                      key={1}
                      visible={newPWConfirmvisiblePassword}
                      onClick={() =>
                        setnewPWConfirmVisiblePassword(
                          !newPWConfirmvisiblePassword
                        )
                      }
                    />
                  ]}
                />
              )}
            />

            <ButtonsWrapper>
              <FormSpy subscription={{ invalid: true }}>
                {({ invalid }) => (
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    disabled={invalid}
                    onClick={handleSubmit}
                  >
                    REDEFINIR SENHA
                  </Button>
                )}
              </FormSpy>
            </ButtonsWrapper>
          </FieldsWrapper>
        </LoginWrapper>
      )}
    />
  )
}

export default RedefinePassword
