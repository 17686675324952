import React from 'react'
import { FormControl, FormHelperText, TextField } from '@material-ui/core'

type TextFieldType = {
  value: any
  onChange: (value: any) => void
  label: string
  disabled?: boolean
  startAdornments?: JSX.Element[]
  endAdornments?: JSX.Element[]
  type?: string
  error?: string
}

const TextFieldComponent: React.FC<TextFieldType> = ({
  value,
  onChange,
  label,
  disabled,
  startAdornments,
  endAdornments,
  type,
  error,
  ...rest
}) => {
  return (
    <FormControl>
      <TextField
        type={type}
        value={value}
        onChange={e => onChange(e.target.value)}
        label={label}
        disabled={disabled}
        InputProps={{
          startAdornment: startAdornments ? <>{startAdornments}</> : undefined,
          endAdornment: endAdornments ? <>{endAdornments}</> : undefined
        }}
        {...rest}
        error={!!error}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export default TextFieldComponent
