import React from 'react'
import QueryDebounced from 'src/components/Apollo/QueryDebounced'
import { GET_FATURA } from 'src/apollo/faturas/queries'
import { QueryResult } from 'react-apollo'
import DataGridScroll from 'src/components/DataGrid/DataGridScroll'

import {
  DecisionDialog,
  DecisionDialogHeader,
  DecisionDialogHeaderTitle,
  DecisionDialogBody,
  DecisionDialogHeaderCloseIcon
} from 'src/components/Dialogs/utils'
import { IconButton } from '@material-ui/core'
import { CloudDownloadRounded } from '@material-ui/icons'
import { get } from 'lodash'

type FaturaAnexosModalTypes = {
  faturaId: number
  onClose: () => void
}

const FaturaAnexosModal: React.FC<FaturaAnexosModalTypes> = ({
  faturaId,
  onClose
}) => {
  const columns = [
    {
      field: 'url',
      headerName: 'Baixar',
      flex: 10,
      renderCell: ({ value }: any) => {
        return (
          <IconButton>
            <a href={value} rel="noreferrer" target={'_blank'}>
              <CloudDownloadRounded />
            </a>
          </IconButton>
        )
      }
    },

    {
      field: 'nome',
      headerName: 'Nome',
      flex: 15
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      flex: 30
    }
  ]

  return (
    <DecisionDialog open={Boolean(faturaId)} onClose={onClose}>
      <>
        <DecisionDialogHeader>
          <>
            <DecisionDialogHeaderTitle icon={undefined} title={'Anexos'} />
            {onClose && <DecisionDialogHeaderCloseIcon onClose={onClose} />}
          </>
        </DecisionDialogHeader>
        <DecisionDialogBody>
          <QueryDebounced
            skip={!faturaId}
            query={GET_FATURA}
            variables={{ id: faturaId }}
          >
            {(result: QueryResult) => {
              const loading = result.loading

              const faturas = get(result, 'data.meuUsuarioPortal.faturas') || []

              const requisicoes = faturas.reduce((acc: any[], curr: any) => {
                const requisicoes = get(curr, 'requisicoes') || []
                return [...acc, ...requisicoes]
              }, [])

              const anexos = requisicoes.reduce((acc: any[], curr: any) => {
                const anexos = get(curr, 'anexos') || []
                return [...acc, ...anexos]
              }, [])

              return (
                <div>
                  <DataGridScroll
                    rows={anexos}
                    loading={loading}
                    columns={columns}
                  />
                </div>
              )
            }}
          </QueryDebounced>
        </DecisionDialogBody>
      </>
    </DecisionDialog>
  )
}
export default FaturaAnexosModal
