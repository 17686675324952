import React from 'react'
import { Form, FormSpy } from 'react-final-form'
import TextField from 'src/components/inputs/TextField'
import DecisionField from 'src/components/Forms/Field'
import client from 'src/config/apolloClient'
import { FORGOT_MY_PASSWORD } from 'src/apollo/usuario/mutations'
import { Button } from '@material-ui/core'
import { get } from 'lodash'
import useDecisionSnackbar from 'src/hooks/useSnackbar'
import {
  FieldsWrapper,
  ButtonsWrapper,
  LoginWrapper,
  TitleCenter
} from './LoginWrapper'

import { validate } from './forgotMyPasswordValidation'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'

const SubTitleWrapper = styled('div')`
  overflow-wrap: break-word;
`

const ForgotMyPassword = (): JSX.Element => {
  const { openSuccessSnackbar, openErrorSnackbar } = useDecisionSnackbar()
  const history = useHistory()

  const handleSubmit = async ({ empresa, email }: any) => {
    try {
      const response = await client.mutate({
        mutation: FORGOT_MY_PASSWORD,
        variables: { empresa, email }
      })

      const { success, successMessage, errorMessage } =
        get(response, 'data.forgotPasswordPortal') || {}

      if (success) {
        openSuccessSnackbar(successMessage)
        localStorage.setItem('empresa', empresa)
        localStorage.setItem('email', email)
        history.push('/login')
      } else {
        openErrorSnackbar(errorMessage)
      }
    } catch (err) {
      openErrorSnackbar('Erro')
    }
  }

  return (
    <Form
      initialValues={{
        empresa: localStorage.getItem('empresa'),
        email: localStorage.getItem('email')
      }}
      validate={validate}
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <LoginWrapper>
          <FieldsWrapper>
            <TitleCenter>Redefina Senha</TitleCenter>

            <SubTitleWrapper>
              Para redefinir sua senha, digite abaixo a empresa e o e-mail que
              você usa para acessar o Portal de Cliente
            </SubTitleWrapper>

            <DecisionField
              name="empresa"
              render={props => <TextField label={'Empresa'} {...props} />}
            />

            <DecisionField
              name="email"
              render={props => <TextField label={'E-mail'} {...props} />}
            />

            <ButtonsWrapper>
              <FormSpy subscription={{ invalid: true }}>
                {({ invalid }) => (
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    disabled={invalid}
                    onClick={handleSubmit}
                  >
                    REDEFINIR SENHA
                  </Button>
                )}
              </FormSpy>
            </ButtonsWrapper>
          </FieldsWrapper>
        </LoginWrapper>
      )}
    />
  )
}

export default ForgotMyPassword
