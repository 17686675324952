import { isNumber, isFinite } from 'lodash'

export function toLocaleCurrency(
  value: number,
  locale = 'pt-BR',
  currency = 'BRL'
): string {
  if (isNumber(value) && isFinite(value)) {
    return value.toLocaleString(locale, { style: 'currency', currency })
  } else {
    return ''
  }
}

export function toLocaleNumber(value: number, locale = 'pt-BR'): string {
  if (isNumber(value) && isFinite(value)) {
    return value.toLocaleString(locale, { minimumFractionDigits: 2 })
  } else {
    return ''
  }
}
