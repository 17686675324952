import { get } from 'lodash'
import Yup, { validateYupSchema } from 'src/utils/yup'

function passwordsMatch(valuea: any, obj: any) {
  const newPassword = get(obj, 'parent.newPassword')
  const newPasswordConfirm = get(obj, 'parent.newPasswordConfirm')

  return newPassword === newPasswordConfirm
}

const schema = Yup.object().shape({
  newPassword: Yup.string()
    .required()
    .nullable(false)
    .test('match', 'Devem ser iguais', passwordsMatch),
  newPasswordConfirm: Yup.string()
    .required()
    .nullable(false)
    .test('match', 'Devem ser iguais', passwordsMatch)
})

export type LoginInput = {
  newPassword: string
  newPasswordConfirm: string
}

type LoginValidationOutput = {
  newPassword?: string
  newPasswordConfirm?: string
}

export const validate = async (
  values: LoginInput
): Promise<LoginValidationOutput> => {
  try {
    await validateYupSchema(schema, values)
    return {}
  } catch (err: any) {
    return err
  }
}
