import React from 'react'

import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { FormHelperText, makeStyles } from '@material-ui/core'

type DatepickerInputProps = {
  value: Date | null
  onChange: (value: any) => void
  label?: string
  error?: string
  placeholder?: string
}

const useStyles = makeStyles({
  root: {
    fontSize: '1.4rem!important'
  }
})

const Datepicker: React.FC<DatepickerInputProps> = ({
  value,
  onChange,
  label,
  error,
  placeholder,
  ...rest
}) => {
  const handleChange = (valueFromDatePicker: MaterialUiPickersDate) => {
    onChange(valueFromDatePicker)
  }

  const classes = useStyles()

  return (
    <div>
      <KeyboardDatePicker
        inputProps={{
          className: classes.root
        }}
        invalidDateMessage={''}
        placeholder={placeholder}
        label={label}
        format="dd/MM/yyyy"
        value={value}
        onChange={handleChange}
        {...rest}
        error={!!error}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </div>
  )
}

export default Datepicker
